// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".half-circle-spinner .circle-1{border-top-color:var(--color-primary)!important}.half-circle-spinner .circle-2{border-bottom-color:var(--color-secondary)!important}.main-menu{z-index:6}.main-menu .primary--text,.main-menu .v-list__tile--active{color:var(--color-active)!important}@media only all and (max-width:959px){.main-menu .hidden-xs{display:none}}.main-nav-header.v-toolbar--fixed{z-index:5}", ""]);
// Exports
exports.locals = {
	"primaryColor": "#036",
	"secondaryColor": "#e94c26",
	"accentColor": "#c41230"
};
module.exports = exports;
