import { EdgeDocumentsApi, EdgeEnrollmentApi } from '@trialcard/apigateway.client';
import { EdgePatientEnrollmentApi } from '@trialcard/apigateway.client/edgePatientEnrollmentApi';
import {
    EnrollPatientRequestModel,
    EnterpriseCoordinatorAccountServiceModelsAccountContactPreferenceModel,
    EnterpriseCoordinatorAccountServiceModelsAccountContactUpsearchModel,
    EnterpriseCoordinatorAccountUpsearchRequestModel,
    EnterpriseCoordinatorPatientServiceModelsPatientUpsearchModel,
    EnterpriseCoordinatorSharedAddressUpsearchModel,
    EnterpriseCoordinatorSharedEmailAddressUpsearchModel,
    EnterpriseCoordinatorSharedPhoneUpsearchModel,
    PayorCoverageEligibilityRequestModel,
    UpsearchModelEnterpriseCoordinatorAccountServiceModelsAccountUpsearchModel,
    UpsearchModelEnterpriseCoordinatorPatientServiceModelsPatientUpsearchModel,
    UpsearchModelEnterpriseCoordinatorPayorServiceModelsPayorUpsearchModel,
    UpsearchModelEnterpriseCoordinatorPrescriberServiceModelsPrescriberUpsearchModel,
    UpsearchModelEnterpriseCoordinatorSiteServiceModelsSiteUpsearchModel,
} from '@trialcard/apigateway.models';
import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import { Gender, RoleType, AccountExternalIdType } from '@trialcard/enums';
import { Moment } from 'moment';
import { Resolve } from 'vue-di';
import { InjectModule, InjectVuexModule } from 'vue-di/vuex';
import { VUFile } from 'vue-upload-component';
import { Action, Mutation } from 'vuex-module-decorators';
import DocumentUploadModule from './documentUpload';

import { DocumentFunctions } from '~/components/DocumentUpload/document-functions';
import { Settings } from '~/services/settings';
import SurveyModule from '~/store/survey';
import { rolling365 } from '~/tests/e2e/@data/shared/dates';

@InjectModule({ stateFactory: true }, module)
export default class EnrollmentModule extends InjectVuexModule {
    @Resolve
    public uploadModule!: DocumentUploadModule;

    @Resolve
    public documentFunctions!: DocumentFunctions;

    @Resolve
    documentApi!: EdgeDocumentsApi;

    @Resolve
    public patientEnrollmentApi!: EdgePatientEnrollmentApi;

    @Resolve
    public enrollmentApi!: EdgeEnrollmentApi;

    @Resolve
    public settings!: Settings;

    @Resolve
    public survey!: SurveyModule;

    path = '';

    isCareGiver: boolean | null = null;

    pathCheck = '';

    showTransitionGrayBox = false;

    defaultPatientInformationData = {
        firstName: '' as string,
        lastName: '' as string,
        dateOfBirth: null as Moment | null,
        addresses: [] as EnterpriseCoordinatorSharedAddressUpsearchModel[],
        emailAddresses: [] as EnterpriseCoordinatorSharedEmailAddressUpsearchModel[],
        phoneNumbers: [] as EnterpriseCoordinatorSharedPhoneUpsearchModel[],
        gender: null as Gender | null,
        isJuvenile: '' as string,
        guardianName: '' as string,
        relationshipToPatient: '' as string,
    };
    defaultHcpPatientInformationData = {
        firstName: '' as string,
        lastName: '' as string,
        dateOfBirth: null as Moment | null,
        email: '' as string,
        homePhone: '',
        phone: '',
        gender: '',
        addressOne: '' as string,
        city: '' as string,
        state: '' as string,
        zip: '' as string,
        isJuvenile: '' as string,
        guardianName: '' as string,
        relationshipToPatient: '' as string,
        hearing: '' as string,
        Language: '' as string,
        patientIs: '' as string,
        allServices: false as boolean,
        benefitsAuthorization: false as boolean,
        authorizationSupport: false as boolean,
        quickStart: false as boolean,
        financialAssistance: false as boolean,
        starterKit: false as boolean,
        injectionTraining: false as boolean,
        CopayProgram: false as boolean
    };

    defaultTransitionPatientInformationData = {
        firstName: '' as string,
        lastName: '' as string,
        dateOfBirth: null as Moment | null,
        addresses: [] as EnterpriseCoordinatorSharedAddressUpsearchModel[],
        emailAddresses: [] as EnterpriseCoordinatorSharedEmailAddressUpsearchModel[],
        phoneNumbers: [] as EnterpriseCoordinatorSharedPhoneUpsearchModel[],
        isJuvenile: '' as string,
        guardianName: '' as string,
        relationshipToPatient: '' as string,
        gender: null as Gender | null,
    };

    defaultCareGiverInformationData = {
        firstName: '' as string,
        lastName: '' as string,
        addresses: [] as EnterpriseCoordinatorSharedAddressUpsearchModel[],
        emailAddresses: [] as EnterpriseCoordinatorSharedEmailAddressUpsearchModel[],
        phoneNumbers: [] as EnterpriseCoordinatorSharedPhoneUpsearchModel[],
    };
    currentJuvinileStatus = '';

    defaultPrescriberData = [
        {
            model: {
                firstName: '',
                lastName: '',
                npi: '',
                accountRelationshipIsPrimary: true,
                prescriberLicenses: [],
                externalIds: [],
                addresses: [],
                phoneNumbers: [],
                emailAddresses: [],
            },
        },
    ] as UpsearchModelEnterpriseCoordinatorPrescriberServiceModelsPrescriberUpsearchModel[];

    defaultSiteData = [
        {
            model: {
                accountRelationshipIsPrimary: true,
                siteRoleType: RoleType.PrescriberSite,
                pointOfContacts: [],
                description: '',
                addresses: [
                    {
                        addressOne: '',
                        addressTwo: '',
                        zip: '',
                        city: '',
                        state: '',
                    },
                ],
                contacts: [],
                emailAddresses: [],
                externalIds: [],
                phoneNumbers: [],
                globalIds: [],
            },
        },
    ] as UpsearchModelEnterpriseCoordinatorSiteServiceModelsSiteUpsearchModel[];

    defaultPayorData = [
        {
            model: {
                payorId: (null as unknown) as number,
                payorType: (null as unknown) as number,
                accountRelationshipIsPrimary: true,
                payorBin: '',
                payorPcn: '',
                accountRelationshipCoverageOrder: 0,
                accountRoleType: RoleType.Unknown,
                payorGroup: '',
                payorName: '',
                webAddresses: [],
                addresses: [],
                phoneNumbers: [
                    {
                        phoneType: 1,
                        phoneNumber: '',
                        phonePurpose: 1,
                    },
                    {
                        phoneType: 3,
                        phoneNumber: '',
                        phonePurpose: 8,
                    },
                ],
                emailAddresses: [],
                contacts: [],
                payorBenefit: {
                    dateOfBirth: null as Moment | null,
                    policyHolderFirstName: '',
                    policyHolderLastName: '',
                    relationshipToPatient: undefined,
                    policyNumber: '',
                    planType: undefined,
                },
            },
        },
    ] as UpsearchModelEnterpriseCoordinatorPayorServiceModelsPayorUpsearchModel[];

    defaultContactPreferences = [] as EnterpriseCoordinatorAccountServiceModelsAccountContactPreferenceModel[];

    public defaultSharpsContainerData = {
        firstName: '',
        lastName: '',
        addressOne: '',
        addressTwo: '',
        dateOfBirth: '',
        zip: '',
        city: '',
        state: '',
        homePhone: '',
        phone: '',
        consentCheckbox: false as boolean,
        tncCheckbox: false as boolean,
        fcraCheckbox: false as boolean,
        tcpaConsentCheckbox: false as boolean,
        signName: '',
    };
    public defaultWelcomeKitData = {
        firstName: '',
        lastName: '',
        addressOne: '',
        addressTwo: '',
        dateOfBirth: '',
        zip: '',
        city: '',
        state: '',
        homePhone: '',
        phone: '',
        consentCheckbox: false as boolean,
        tncCheckbox: false as boolean,
        fcraCheckbox: false as boolean,
        tcpaConsentCheckbox: false as boolean,
        signName: '',
    };
    public defaultNursingData = {
        firstName: '',
        lastName: '',
        dateOfBirth: '',
        Date1: '',
        Date2: '',
        Date3: '',
        timeSlote1: '',
        timeSlote2: '',
        timeSlote3: '',
        homePhone: '',
        phone: '',
    };
    public defaultDiagnoseData = {
        AnkylosingSpondylitis: false as boolean,
        CrohnsDisease: false as boolean,
        PlaquePsoriasis: false as boolean,
        PsoriaticArthritis: false as boolean,
        RheumatoidArthritis: false as boolean,
        HidradenitisSuppurativa: false as boolean,
        UlcerativeColitis: false as boolean,
        JuvenileIdiopathicArthritis: false as boolean,
        PediatricCrohnDisease: false as boolean,
        Uveitis: false as boolean,
    };

    defaultMemberNumber = '<XXXXXXXXXXX>';
    defaultCordavisMemberNumber = '<XXXXXXXXXXX>'
    defaultExpirationDate = rolling365;


    public defaultAuthorizationData = {
        consentCheckbox: false as boolean,
        tncCheckbox: false as boolean,
        fcraCheckbox: false as boolean,
        tcpaConsentCheckbox: false as boolean,
        signName: '',
    };

    public defaultInjectionTrainingData = {
        firstName: '',
        lastName: '',
        dateOfBirth: '',
        homePhone: '',
        phone: '',
        selectPreferedMethod: '',
        preferedDate1: '',
        preferedDate2: '',
        preferedDate3: '',
        preferredTimeSlot1: '',
        preferredTimeSlot2: '',
        preferredTimeSlot3: '',
    };
    public defaultpharmacyPrescriptionData = {
        specialtyPharmacy: false as boolean,
        preferredSpecialty: '',
        numberOfRefills: '',
        prescriberSignature: '',
    };

    public defaultRheumatoidArthritis = {
        rheumatoidColumn11: false as boolean,
        rheumatoidColumn12: false as boolean,
        rheumatoidColumn13: false as boolean,
        rheumatoidColumn14: false as boolean,
        rheumatoidColumn21: false as boolean,
        rheumatoidColumn22: false as boolean,
        rheumatoidColumn23: false as boolean,
        rheumatoidColumn31: false as boolean,
        rheumatoidColumn32: false as boolean,
        refills: ''
    }
    public defaultPsoriaticArthritisOrAnkylosingSpondylitis = {
        PsoriaticArthritisOrAnkylosingSpondylitis11: false as boolean,
        PsoriaticArthritisOrAnkylosingSpondylitis21: false as boolean,
        PsoriaticArthritisOrAnkylosingSpondylitis22: false as boolean,
    }
    public defaultCrohnsDiseasOrUlcerativeColitis = {
        CrohnsDiseasOrUlcerativeColitis11: false as boolean,
        CrohnsDiseasOrUlcerativeColitis12: false as boolean,
        CrohnsDiseasOrUlcerativeColitis13: false as boolean,
        CrohnsDiseasOrUlcerativeColitis14: false as boolean,
        CrohnsDiseasOrUlcerativeColitis15: false as boolean,
        CrohnsDiseasOrUlcerativeColitis21: false as boolean,
        CrohnsDiseasOrUlcerativeColitis22: false as boolean,
        CrohnsDiseasOrUlcerativeColitis31: false as boolean,
        CrohnsDiseasOrUlcerativeColitis32: false as boolean,
        CrohnsDiseasOrUlcerativeColitis33: false as boolean,
        CrohnsDiseasOrUlcerativeColitis34: false as boolean,
        CrohnsDiseasOrUlcerativeColitis35: false as boolean,
        refills31: "",
        refills32: "",
        refills33: ""
    }
    public defaultPlaquePsoriasis = {
        PlaquePsoriasis11: false as boolean,
        PlaquePsoriasis12: false as boolean,
        PlaquePsoriasis13: false as boolean,
        PlaquePsoriasis14: false as boolean,
        PlaquePsoriasis15: false as boolean,
        PlaquePsoriasis21: false as boolean,
        PlaquePsoriasis22: false as boolean,
        PlaquePsoriasis31: false as boolean,
        PlaquePsoriasis32: false as boolean,
        PlaquePsoriasis33: false as boolean,
        PlaquePsoriasis34: false as boolean,
        PlaquePsoriasis35: false as boolean,
        refills31: '',
        refills32: '',
        refills33: ''
    }
    public defaultHidradenitisSuppurativa = {
        HidradenitisSuppurativa11: false as boolean,
        HidradenitisSuppurativa12: false as boolean,
        HidradenitisSuppurativa13:false as boolean,
        HidradenitisSuppurativa14: false as boolean,
        HidradenitisSuppurativa15: false as boolean,
        HidradenitisSuppurativa16: false as boolean,
        HidradenitisSuppurativa17: false as boolean,
        HidradenitisSuppurativa21: false as boolean,
        HidradenitisSuppurativa22: false as boolean,
        HidradenitisSuppurativa23: false as boolean,
        HidradenitisSuppurativa31: false as boolean,
        HidradenitisSuppurativa32: false as boolean,
        HidradenitisSuppurativa33: false as boolean,
        HidradenitisSuppurativa34: false as boolean,
        HidradenitisSuppurativa35: false as boolean,
        refills31: '',
        refills32: '',
        refills33: ''
    }
    
    public defaultJuvenileidiopathicArthritis = {
        JuvenileidiopathicArthritis11: false as boolean,
        JuvenileidiopathicArthritis12: false as boolean,
        JuvenileidiopathicArthritis21: false as boolean,
        JuvenileidiopathicArthritis22: false as boolean,
        JuvenileidiopathicArthritis31: false as boolean,
        JuvenileidiopathicArthritis32: false as boolean,
        JuvenileidiopathicArthritis33: false as boolean,
        JuvenileidiopathicArthritis41: false as boolean,
        JuvenileidiopathicArthritis42: false as boolean,
        JuvenileRefills: ''
    }
    public defaultPediatriccrohnsDisease = {
        PediatriccrohnsDisease11: false as boolean,
        PediatriccrohnsDisease12: false as boolean,
        PediatriccrohnsDisease13: false as boolean,
        PediatriccrohnsDisease21: false as boolean,
        PediatriccrohnsDisease22: false as boolean,
        PediatriccrohnsDisease23: false as boolean,
        PediatriccrohnsDisease31: false as boolean,
        PediatriccrohnsDisease32: false as boolean,
        PediatriccrohnsDisease33: false as boolean,
        PediatriccrohnsDisease41: false as boolean,
        PediatriccrohnsDisease51: false as boolean,
        PediatriccrohnsDisease52: false as boolean,
        PediatriccrohnsDisease53: false as boolean,
        PediatriccrohnsDisease54: false as boolean,
        PediatriccrohnsDisease61: false as boolean,
        PediatriccrohnsDisease62: false as boolean,
        PediatriccrohnsDisease71: false as boolean,
        Refills33: '',
        Refills44: '',
    }

    public defaultpharmaTableData = {
        rowOneCheckbox21: false as boolean,
        rowOneCheckbox22: false as boolean,
        rowOneCheckbox31: false as boolean,
        rowOneCheckbox32: false as boolean,

        rowTwoCheckbox21: false as boolean,
        rowTwoCheckbox22: false as boolean,
        rowTwoCheckbox23: false as boolean,
        rowTwoCheckbox24: false as boolean,
        rowTwoCheckbox31: false as boolean,
        rowTwoCheckbox32: false as boolean,

        rowThreeCheckbox21: false as boolean,
        rowThreeCheckbox22: false as boolean,
        rowThreeCheckbox23: false as boolean,
        rowThreeCheckbox24: false as boolean,
        rowThreeCheckbox25: false as boolean,
        rowThreeCheckbox26: false as boolean,
        rowThreeCheckbox31: false as boolean,
        rowThreeCheckbox32: false as boolean,
        rowThreeCheckbox33: false as boolean,
        rowThreeCheckbox34: false as boolean,
        rowThreeCheckbox35: false as boolean,
        rowThreeCheckbox36: false as boolean,

        rowThreeCheckbox37: false as boolean,
        rowThreeCheckbox38: false as boolean,
        rowThreeCheckbox39: false as boolean,
        rowThreeCheckbox40: false as boolean,
        rowThreeCheckbox41: false as boolean,
        rowThreeCheckbox42: false as boolean,

        rowCheckbox41: false as boolean,
        rowCheckbox42: false as boolean,
        rowCheckbox43: false as boolean,
        rowCheckbox44: false as boolean,
        rowCheckbox45: false as boolean,
        rowCheckbox46: false as boolean,
        rowCheckbox47: false as boolean,
        rowCheckbox48: false as boolean,
        rowCheckbox49: false as boolean,
        rowCheckbox50: false as boolean,
    };

    public defaultHcpPrescriberInformation = {
        priorAuthorization: false,
        benefitVerification: false,
        firstName: '',
        lastName: '',
        officeContactName: '',
        siteName: '',
        address: '',
        state: '',
        city: '',
        zip: '',
        phone: '',
        fax: '',
        npi: '',
    };
    public defaultDocumentSubmission = {
        enrollDocument: '',
    };

    public defaultHealthInsuranceData = {
        documentsIncluded: false as boolean,
        privateCommercial: false as boolean,
        medicareCheckbox: false as boolean,
        govFundCheckbox: false as boolean,
        notInsuredCheckbox: false as boolean,
        medicalBeneficiaryName: '',
        medicalInsuranceCarrier: '',
        medicalPolicy: '',
        medicalGroup: '',
        prescriptionBeneficiaryName: '',
        prescriptionInsuranceCarrier: '',
        rxGroup: '',
        rxId: '',
        rxBin: '',
        rxPcn: '',
    };

    public defaultUploadPDFData = {
        docsForUpload: [] as VUFile[],
    };

    public docsForUploadCLS: VUFile[] = [];

    public defaultPatientSelectedServices = ['', '', '', ''];

    public defaultsummaryData = {};

    defaultContacts = [] as EnterpriseCoordinatorAccountServiceModelsAccountContactUpsearchModel[];

    defaultCoverageAreas = [] as PayorCoverageEligibilityRequestModel[];

    memberNumber = this.defaultMemberNumber;
    cordavisMemberNumber = this.defaultCordavisMemberNumber;
    expirationDate = this.defaultExpirationDate;
    patientInformationData = { ...this.defaultPatientInformationData };
    patientHcpInformationData = { ...this.defaultHcpPatientInformationData };
    patientTransitionInformationData = { ...this.defaultTransitionPatientInformationData };
    careGiverInformationData = { ...this.defaultCareGiverInformationData };
    prescriberData = [...this.defaultPrescriberData];
    siteData = [...this.defaultSiteData];
    payorData = [...this.defaultPayorData];
    contactPreferences = [...this.defaultContactPreferences];
    welcomeKitData = { ...this.defaultWelcomeKitData };
    nursingData = { ...this.defaultNursingData };
    AuthorizationData = { ...this.defaultAuthorizationData };
    InjectionTrainingData = this.defaultInjectionTrainingData;
    sharpsContainerData = { ...this.defaultSharpsContainerData };
    pharmacyPrescriptionData = { ...this.defaultpharmacyPrescriptionData };
    pharmaTableData = { ...this.defaultpharmaTableData };
    RheumatoidArthritisData = { ...this.defaultRheumatoidArthritis };
    PsoriaticArthritisOrAnkylosingSpondylitisData = { ...this.defaultPsoriaticArthritisOrAnkylosingSpondylitis };
    CrohnsDiseasOrUlcerativeColitisData = { ...this.defaultCrohnsDiseasOrUlcerativeColitis };
    PlaquePsoriasisData = { ...this.defaultPlaquePsoriasis };
    HidradenitisSuppurativaData = { ...this.defaultHidradenitisSuppurativa };
    JuvenileidiopathicArthritisData = { ...this.defaultJuvenileidiopathicArthritis };
    PediatriccrohnsDiseaseData = { ...this.defaultPediatriccrohnsDisease };
    DiagnoseData = { ...this.defaultDiagnoseData };
    hcpPrescriberInformation = { ...this.defaultHcpPrescriberInformation };
    healthInsuranceData = { ...this.defaultHealthInsuranceData };
    uploadPDFData = { ...this.defaultUploadPDFData };
    DocumentSubmissionData = { ...this.defaultDocumentSubmission };
    contacts = [...this.defaultContacts] as EnterpriseCoordinatorAccountServiceModelsAccountContactUpsearchModel[];
    patientTransitionContacts = [...this.defaultContacts] as EnterpriseCoordinatorAccountServiceModelsAccountContactUpsearchModel[];
    payorCoverageAreas = [...this.defaultCoverageAreas];

    @Mutation
    public setHyrimozPath(alternative: string) {
        this.path = alternative;
        localStorage.setItem('hyrimozPathName', alternative);
    }

    @Mutation
    updatePharmacyPrescriptionInformationData(
        newPharmacyPrescriptionInformationData: typeof EnrollmentModule.prototype.defaultpharmacyPrescriptionData
    ) {
        this.pharmacyPrescriptionData = { ...newPharmacyPrescriptionInformationData };
    }

    @Mutation
    updatePharmaTableData(newPharmaTableData: typeof EnrollmentModule.prototype.defaultpharmaTableData) {
        this.pharmaTableData = { ...newPharmaTableData };
    }

    @Mutation
    updateRheumatoidArthritis(newRheumatoidArthritis: typeof EnrollmentModule.prototype.defaultRheumatoidArthritis) {
        this.RheumatoidArthritisData = { ...newRheumatoidArthritis};
    }
    @Mutation
    updatePsoriaticArthritisOrAnkylosingSpondylitis(newPsoriaticArthritisOrAnkylosingSpondylitis: typeof EnrollmentModule.prototype.defaultPsoriaticArthritisOrAnkylosingSpondylitis) {
        this.PsoriaticArthritisOrAnkylosingSpondylitisData = { ...newPsoriaticArthritisOrAnkylosingSpondylitis};
    }

    @Mutation
    updateCrohnsDiseasOrUlcerativeColitis(newCrohnsDiseasOrUlcerativeColitis: typeof EnrollmentModule.prototype.defaultCrohnsDiseasOrUlcerativeColitis) {
        this.CrohnsDiseasOrUlcerativeColitisData = { ...newCrohnsDiseasOrUlcerativeColitis};
    }
    @Mutation
    updatePlaquePsoriasis(newPlaquePsoriasis: typeof EnrollmentModule.prototype.defaultPlaquePsoriasis) {
        this.PlaquePsoriasisData = { ...newPlaquePsoriasis};
    }

    @Mutation
    updateJuvenileidiopathicArthritis(newJuvenileidiopathicArthritis: typeof EnrollmentModule.prototype.defaultJuvenileidiopathicArthritis) {
        this.JuvenileidiopathicArthritisData = { ...newJuvenileidiopathicArthritis};
    }

    @Mutation
    updatePediatriccrohnsDisease(newPediatriccrohnsDisease: typeof EnrollmentModule.prototype.defaultPediatriccrohnsDisease) {
        this.PediatriccrohnsDiseaseData = { ...newPediatriccrohnsDisease};
    }

    @Mutation
    updateHidradenitisSuppurativa(newHidradenitisSuppurativa: typeof EnrollmentModule.prototype.defaultHidradenitisSuppurativa) {
        this.HidradenitisSuppurativaData = { ...newHidradenitisSuppurativa};
    }

    @Mutation
    public async updateHealthInsuranceInformataion(newHealthInsurance: typeof EnrollmentModule.prototype.defaultHealthInsuranceData) {
        this.healthInsuranceData = { ...newHealthInsurance };
    }
    patientSelectedServices = [...this.defaultPatientSelectedServices];

    summaryData = { ...this.defaultsummaryData };

    @Mutation
    updateSummaryData(newSummaryData: typeof EnrollmentModule.prototype.defaultsummaryData) {
        this.summaryData = { ...newSummaryData };
    }

    @Mutation
    public async updatePatientInformation(newPatientInformation: typeof EnrollmentModule.prototype.patientInformationData) {
        this.patientInformationData = { ...this.patientInformationData, ...newPatientInformation };
    }

    @Mutation
    public async updatepPatientHcpInformation(newPatientHcpInformation: typeof EnrollmentModule.prototype.patientHcpInformationData) {
        this.patientHcpInformationData = { ...this.patientHcpInformationData, ...newPatientHcpInformation };
    }

    @Mutation
    public async updatepPatientTransitionInformation(
        newPatientTransitionInformation: typeof EnrollmentModule.prototype.patientTransitionInformationData
    ) {
        this.patientTransitionInformationData = { ...this.patientTransitionInformationData, ...newPatientTransitionInformation };
    }

    @Mutation
    public async updatePrescribers(newPrescriberData: UpsearchModelEnterpriseCoordinatorPrescriberServiceModelsPrescriberUpsearchModel[]) {
        this.prescriberData = [...newPrescriberData];
    }

    @Mutation
    public async updateSites(newSites: UpsearchModelEnterpriseCoordinatorSiteServiceModelsSiteUpsearchModel[]) {
        this.siteData = [...newSites];
    }

    @Mutation
    public async updatePayors(newPayorData: UpsearchModelEnterpriseCoordinatorPayorServiceModelsPayorUpsearchModel[]) {
        this.payorData = [...newPayorData];
    }

    @Mutation
    public async updateCareGiverInformation(newCareGiverInformation: typeof EnrollmentModule.prototype.careGiverInformationData) {
        this.patientInformationData = { ...this.patientInformationData, ...newCareGiverInformation };
    }

    @Mutation
    public async updateContactPreferences(newContactPreferences: EnterpriseCoordinatorAccountServiceModelsAccountContactPreferenceModel[]) {
        console.log('newContactPreferences:', newContactPreferences);
        this.contactPreferences = [...newContactPreferences];
    }

    @Mutation
    public async updateUploadPDF(newUploadPDF: typeof EnrollmentModule.prototype.uploadPDFData) {
        this.uploadPDFData = { ...newUploadPDF };
    }

    @Mutation
    public addSMNForm(data: any) {
        // this.receipts.files.push(data as any)
        this.docsForUploadCLS = [];
        this.docsForUploadCLS[0] = data;
    }

    @Mutation
    public addUploadDocPDFToSMN() {
        // this.receipts.files.push(data as any)
        this.uploadPDFData.docsForUpload.forEach(file => {
            this.docsForUploadCLS.push(file);
        });
    }

    async uploadDocument(request: {
        documentTypeId: string | null;
    }): Promise<{
        success: boolean;
        data: {};
        messages: string[];
    }> {
        const files: Array<{ files: File[] }> = [];
        // To create a PDF file
        const mergedFileByteArray = await this.documentFunctions.mergeFiles(this.docsForUploadCLS);
        const fileBlob = new Blob([mergedFileByteArray.buffer], { type: 'applicaton/pdf' });
        const file = new File([fileBlob], 'PEF.pdf', { type: 'application/pdf', lastModified: Date.now() });
        const mergedPdf = { file };
        files.push({ files: [mergedPdf.file] });

        if (this.settings.testClsPDF === 'true') {
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(mergedPdf.file);
            const fileName = 'TestingPEF_PDF';
            link.download = fileName;
            link.click();
        }

        const uploadDocumentResponse = {
            success: false,
            data: {},
            messages: [] as string[],
        };
        try {
            if (files.length > 0) {
                const submitClaimResponses = await Promise.all(
                    files.map(async f => {
                        const form = new FormData();
                        if (request.documentTypeId) form.append('type', request.documentTypeId);
                        f.files.map(async x => {
                            form.append('attachment', x);
                        });
                        form.append('externalPartyCode', 'CLS');
                        form.append('externalProgramCode', 'HYRI');
                        const axios: AxiosInstance = (this.documentApi as any).axios;
                        const axiosRequest: AxiosRequestConfig = {
                            url: 'edge/documents/v1/Document/upload',
                            method: 'POST',
                            data: form,
                            headers: {
                                'x-program-id': this.settings.clsProgramId,
                            },
                        };
                        await Promise.all([(this.documentApi as any).authentications.Token.applyToRequest(axiosRequest)]);
                        return axios.request(axiosRequest);
                    })
                );
                for (const response of submitClaimResponses) {
                    if (!response.data.success) {
                        uploadDocumentResponse.success = false;
                        uploadDocumentResponse.messages.push(...response.data.messages!);
                    } else {
                        uploadDocumentResponse.data = response.data.data!;
                        uploadDocumentResponse.success = true;
                    }
                }
            }
            return uploadDocumentResponse;
        } catch (error) {
            // log error
            console.log(error);
            return uploadDocumentResponse;
        }
    }

    @Mutation
    updateAuthorizationData(newAuthorizationData: typeof EnrollmentModule.prototype.defaultAuthorizationData) {
        this.AuthorizationData = { ...newAuthorizationData };
    }

    @Mutation
    updateInjectionTrainingData(newInjectionTrainingData: typeof EnrollmentModule.prototype.defaultInjectionTrainingData) {
        this.InjectionTrainingData = { ...newInjectionTrainingData };
    }

    @Mutation
    public setMemberNumber(memberNumber: string) {
        this.memberNumber = memberNumber;
    }

    @Mutation
    public setCordavisMemberNumber(memberNumber: string) {
        this.cordavisMemberNumber = memberNumber;
    }

    @Mutation
    public setExpirationDate(expirationDate: Moment) {
        this.expirationDate = expirationDate;
    }

    @Mutation
    public setIsCaregiver(isCareGiver: boolean | null) {
        this.isCareGiver = isCareGiver;
    }

    @Mutation
    updateSharpsContainerData(newSharpsContainerData: typeof EnrollmentModule.prototype.defaultSharpsContainerData) {
        this.sharpsContainerData = { ...newSharpsContainerData };
    }
    @Mutation
    updateWelcomeKitData(newwelcomeKitData: typeof EnrollmentModule.prototype.defaultWelcomeKitData) {
        this.welcomeKitData = { ...newwelcomeKitData };
    }
    @Mutation
    updateNursingData(newNursingData: typeof EnrollmentModule.prototype.defaultNursingData) {
        this.nursingData = { ...newNursingData };
    }

    @Mutation
    updateDiagnoseData(newDiagnoseData: typeof EnrollmentModule.prototype.defaultDiagnoseData) {
        this.DiagnoseData = { ...newDiagnoseData };
    }

    @Mutation
    public async updateHcpPrescriberInformation(
        newHcpPrescriberInformation: typeof EnrollmentModule.prototype.defaultHcpPrescriberInformation
    ) {
        this.hcpPrescriberInformation = { ...newHcpPrescriberInformation };
    }

    @Mutation
    updatePatientSelectedServices(newPatientSelectedServices: typeof EnrollmentModule.prototype.defaultPatientSelectedServices) {
        this.patientSelectedServices = [...newPatientSelectedServices];
    }

    @Mutation
    public setConditionalValue(value: string) {
        this.currentJuvinileStatus = value;
        localStorage.setItem('juvinileStatus', value);
    }

    @Mutation
    public setPatientPath(alternative: string) {
        this.pathCheck = alternative;
        localStorage.setItem('pathCheck', alternative);
    }

    @Mutation
    public setTransitionGrayBox(showTransitionGrayBox: boolean) {
        this.showTransitionGrayBox = showTransitionGrayBox;
    }

    @Mutation
    updateDocumentSubmission(newDocumentSubmission: typeof EnrollmentModule.prototype.defaultDocumentSubmission) {
        this.DocumentSubmissionData = { ...newDocumentSubmission };
    }
    @Action({rawError: true})
    public clearService(){
        this.updateSharpsContainerData(this.defaultSharpsContainerData);
        this.updateWelcomeKitData(this.defaultWelcomeKitData);
        this.updateNursingData(this.defaultNursingData);
        this.updateInjectionTrainingData(this.defaultInjectionTrainingData);
    }

    @Mutation
    public async updateContacts(newContacts: EnterpriseCoordinatorAccountServiceModelsAccountContactUpsearchModel[]) {
        this.contacts = newContacts;
    }

    @Mutation
    public async updatePatientTranitionContacts(newContacts: EnterpriseCoordinatorAccountServiceModelsAccountContactUpsearchModel[]) {
        this.patientTransitionContacts = newContacts;
    }

    @Mutation
    public async updatePayorCoverageAreas(newCoverageAreas : PayorCoverageEligibilityRequestModel[]){
        this.payorCoverageAreas = [...newCoverageAreas];
    }

    @Action({ rawError: true })
    public clearStore() {
        this.updatepPatientTransitionInformation(this.defaultTransitionPatientInformationData);
        this.setMemberNumber(this.defaultMemberNumber);
        this.setExpirationDate(this.defaultExpirationDate);
        this.updatePatientInformation(this.defaultPatientInformationData);
        this.updatepPatientHcpInformation(this.defaultHcpPatientInformationData);
        this.updateCareGiverInformation(this.defaultCareGiverInformationData);
        this.updatePrescribers(this.defaultPrescriberData);
        this.updateSites(this.defaultSiteData);
        this.updatePayors(this.defaultPayorData);
        this.updateContactPreferences(this.defaultContactPreferences);
        this.updateSharpsContainerData(this.defaultSharpsContainerData);
        this.updateWelcomeKitData(this.defaultWelcomeKitData);
        this.updateNursingData(this.defaultNursingData);
        this.updateInjectionTrainingData(this.defaultInjectionTrainingData);
        this.updateAuthorizationData(this.defaultAuthorizationData);
        this.updatePharmaTableData(this.defaultpharmaTableData);
        this.updatePharmacyPrescriptionInformationData(this.defaultpharmacyPrescriptionData);
        this.updateDiagnoseData(this.defaultDiagnoseData);
        this.updateHcpPrescriberInformation(this.defaultHcpPrescriberInformation);
        this.updatePatientSelectedServices(this.defaultPatientSelectedServices);
        this.updateUploadPDF(this.defaultUploadPDFData);
        this.updateHealthInsuranceInformataion(this.defaultHealthInsuranceData);
        this.updateDocumentSubmission(this.defaultDocumentSubmission);
        this.uploadModule.clearFiles();
        this.updateContacts(this.defaultContacts);
        this.updatePatientTranitionContacts(this.defaultContacts);
        this.updateRheumatoidArthritis(this.defaultRheumatoidArthritis);
        this.updatePediatriccrohnsDisease(this.defaultPediatriccrohnsDisease);
        this.updatePsoriaticArthritisOrAnkylosingSpondylitis(this.defaultPsoriaticArthritisOrAnkylosingSpondylitis);
        this.updateCrohnsDiseasOrUlcerativeColitis(this.defaultCrohnsDiseasOrUlcerativeColitis);
        this.updatePlaquePsoriasis(this.defaultPlaquePsoriasis);
        this.updateJuvenileidiopathicArthritis(this.defaultJuvenileidiopathicArthritis);
        this.updateHidradenitisSuppurativa(this.defaultHidradenitisSuppurativa);
        this.updatePayorCoverageAreas(this.defaultCoverageAreas)
    }

    @Action({ rawError: true })
    public clearEnrollment() {
        this.updatePatientInformation({ ...this.defaultPatientInformationData });
        this.updateWelcomeKitData(this.defaultWelcomeKitData);
        this.updateNursingData(this.defaultNursingData);
        this.updateInjectionTrainingData(this.defaultInjectionTrainingData);
        this.setMemberNumber(this.defaultMemberNumber);
        this.updateAuthorizationData(this.defaultAuthorizationData);
    }

    @Action({ rawError: true })
    public async submitToAPI() {
        let response = null;
        const { prescriberData, siteData, payorData, contactPreferences } = this;
        console.log('contactPreferences from submitToApi:', contactPreferences);
        try {
            const patient = {
                account: {
                    patientData: {
                        canUpdate: true,
                        canInsert: true,
                        skipSearch: false,
                        model: {
                            ...this.patientInformationData,
                            middleName: '',
                            suffix: '',
                            nickname: '',
                        } as EnterpriseCoordinatorPatientServiceModelsPatientUpsearchModel,
                    } as UpsearchModelEnterpriseCoordinatorPatientServiceModelsPatientUpsearchModel,
                    accountData: {
                        canUpdate: true,
                        canInsert: true,
                        skipSearch: false,
                        model: {
                            accountType: 1,
                            accountStatus: 1,
                            contactPreferences,
                            contacts: this.contacts,
                        },
                    } as UpsearchModelEnterpriseCoordinatorAccountServiceModelsAccountUpsearchModel,
                    /* prescriberData,
                    siteData,
                    payorData, */
                    surveySessionData: [this.survey.sessionId] as number[],
                } as EnterpriseCoordinatorAccountUpsearchRequestModel,
            } as EnrollPatientRequestModel;
            const hyrimozRequest = { ...patient}
            const cordavisResponse = await this.patientEnrollmentApi.patientEnrollmentEnrollPatient(patient, this.settings.cordavisProgramId);
            const memberNumber = (cordavisResponse.data &&
                                cordavisResponse.data.data &&
                                cordavisResponse.data.data.enrollment &&
                                cordavisResponse.data.data.enrollment.memberNumbers[0].number) ||
                                '';
            if (cordavisResponse && cordavisResponse.status === 200 && memberNumber ) {
                this.setCordavisMemberNumber(memberNumber);
                hyrimozRequest.account.accountData!.model!.externalIds = [
                    {
                        externalIdType: AccountExternalIdType.THIRDPARTYID,
                        externalId: memberNumber,
                    }
                ]
                response = await this.patientEnrollmentApi.patientEnrollmentEnrollPatient(hyrimozRequest, this.settings.programId);
            } else {
                throw new Error('No member number returned from Cordavis');
            }

        } catch (error){
            response = { status: 400 } as AxiosResponse;
            console.error('Error from enrollment call', error)
        }
        return response;
    }

    @Action({ rawError: true })
    public async submitToTransitionAPI() {
        let response = null;
        const { prescriberData, siteData, payorData, contactPreferences } = this;
        try {
            const patient = {
                account: {
                    patientData: {
                        canUpdate: true,
                        canInsert: true,
                        skipSearch: false,
                        model: {
                            ...this.patientTransitionInformationData,
                            middleName: '',
                            suffix: '',
                            nickname: '',
                        } as EnterpriseCoordinatorPatientServiceModelsPatientUpsearchModel,
                    } as UpsearchModelEnterpriseCoordinatorPatientServiceModelsPatientUpsearchModel,
                    accountData: {
                        canUpdate: true,
                        canInsert: true,
                        skipSearch: false,
                        model: {
                            accountType: 1,
                            accountStatus: 1,
                            contactPreferences,
                            contacts: this.patientTransitionContacts,
                        },
                    } as UpsearchModelEnterpriseCoordinatorAccountServiceModelsAccountUpsearchModel,
                    // prescriberData,
                    // siteData,
                    payorData,
                    surveySessionData: [this.survey.sessionId] as number[],
                } as EnterpriseCoordinatorAccountUpsearchRequestModel,
                shouldVerifyEligibility: true,
                payorCoverageAreas: this.payorCoverageAreas,
            } as EnrollPatientRequestModel;
            response = await this.patientEnrollmentApi.patientEnrollmentEnrollPatient(patient, this.settings.transitionProgramId);
        } catch {
            response = { status: 400 } as AxiosResponse;
        }
        return response;
    }
}
