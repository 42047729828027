export class Settings {
    public constructor(settings: any) {
        Object.assign(this, settings);
    }
}
export interface Settings {
    base: string;
    url(url: string): string;
    name: string;
    author: string;
    description: string;
    minDate: string;
    mainMenu: Array<{ icon: [string, string]; title: string; to: string; exact: boolean }>;
    errorPages: {
        default: { icon: [string, string]; title: string; message: string; statusCode: number };
        [code: string]: { icon: [string, string]; title: string; message: string; statusCode: number };
    };
    card: string;
    headerLogo: string;
    footerLogo: string;
    programId: string;
    cordavisProgramId: string;
    hyrimozTCProgramId: string;
    cordavisTCProgramId: string;
    clsProgramId: string;
    testClsPDF: string;
    vanityHUBEnrollmentPath: string;
    transitionProgramId: string;
    surveyId: string;
    eligibilityFederalQuestion: string;
    eligibilityFederalAnswer: string;
    eligibilityPrescribedQuestion: string;
    eligibilityPrescribedAnswer: string;
    eligibilityCommercialQuestion: string;
    eligibilityCommercialAnswer: string;
    eligibilityTermConditionQuestion: string;
    eligibilityTermConditionAnswer: string;
    eligibilityFederalQuestionProviderPharmacy: string;
    eligibilityFederalAnswerProviderPharmacy: string;
    eligibilityPrescribedQuestionProviderPharmacy: string;
    eligibilityPrescribedAnswerProviderPharmacy: string;
    eligibilityCommercialQuestionProviderPharmacy: string;
    eligibilityCommercialAnswerProviderPharmacy: string;
    eligibilityTermConditionQuestionProviderPharmacy: string;
    eligibilityTermConditionAnswerProviderPharmacy: string;

    eligibilityAgeQuestionTransition: string;
    eligibilityAgeAnswerTransition: string;
    eligibilityFederalQuestionTransition: string;
    eligibilityFederalAnswerTransition: string;
    eligibilityPrescribedQuestionTransition: string;
    eligibilityPrescribedAnswerTransition: string;
    eligibilityCommercialQuestionTransition: string;
    eligibilityCommercialAnswerTransition: string;
    eligibilityTermConditionQuestionTransition: string;
    eligibilityTermConditionAnswerTransition: string;
    eligibilityReimbursementQuestionTransition: string;
    eligibilityReimbursementAnswerTransition: string;
    triangleUp: string;
    triangleDown: string;
    lockEnabled: string;
    lockPassword: string;
    pathQuestion: string;
    pathPatientAnswer: string;
    pathProviderAnswer: string;
    pathPharmacyAnswer: string;
    isJuvenileQuestion: string;
    isJuvenileYes: string;
    isJuvenileNo: string;
    enrollDocumentQuestion: string;
    enrollDocumentByMail: string;
    enrollDocumentElectronically: string;
    consentCheckboxQuestion: string;
    consentCheckboxAnswer: string;
    tncCheckboxQuestion: string;
    tncCheckboxAnswer: string;
    fcraCheckboxQuestion: string;
    fcraCheckboxAnswer: string;
    tcpaConsentCheckboxQuestion: string;
    tcpaConsentCheckboxAnswer: string;
    eligibility_question_4: string;
    eligibility_question_3: string;
    eligibility_question_2: string;
    eligibility_question_1: string;
}
