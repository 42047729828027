import { Mutation, Action } from 'vuex-module-decorators';
import { InjectModule, InjectVuexModule } from 'vue-di/vuex';
import { Resolve } from 'vue-di';
import { SurveySessionValueModel } from '@trialcard/apigateway.models';
import SessionModule from './session';
import { Settings } from '~/services/settings';

export interface IEligibilityQuestions {
    surveySessionId: string;
    questionId: number;
    answerId: number;
}
@InjectModule({ stateFactory: true }, module)
export default class EligibilityModule extends InjectVuexModule {
    @Resolve
    public settings!: Settings;

    @Resolve
    public session!: SessionModule;

    public eligibilityData = [] as SurveySessionValueModel[];

    public checkEligibilityData = [] as SurveySessionValueModel[];

    public isEligible = false;

    @Mutation
    public async updateEligibilityData(newEligibilityData: typeof EligibilityModule.prototype.eligibilityData) {
        if (newEligibilityData.length === 0) {
            this.eligibilityData = [];
            this.checkEligibilityData = [];
        }
        else {
            newEligibilityData.forEach(newItem => {
                const searchItem = this.eligibilityData.filter(item => {
                    if (item.questionId === newItem.questionId) return item;
                    else return null;
                });
                const index = this.eligibilityData.indexOf(searchItem[0]);
                if (index > -1) {
                    this.eligibilityData[index] = newItem;
                } else {
                    this.eligibilityData.push(newItem);
                }
            });
            this.checkEligibilityData = newEligibilityData;
        }
    }

    @Mutation
    public async updateIsEligible(isEligible: boolean) {
        this.isEligible = isEligible;
    }

    @Mutation
    public addOrUpdateEligibilityData(newItem: SurveySessionValueModel) {
        const searchItem = this.eligibilityData.filter(item => {
            if (item.questionId === newItem.questionId) return item;
            else return null;
        });
        const index = this.eligibilityData.indexOf(searchItem[0]);
        if (index > -1) {
            this.eligibilityData[index] = newItem;
        } else {
            this.eligibilityData.push(newItem);
        }
    }

    @Mutation
    public async removeEligibilityData(questionId: string) {
        const searchItem = this.eligibilityData.filter(item => {
            if (item.questionId === Number(questionId)) return item;
            else return null;
        });
        const index = this.eligibilityData.indexOf(searchItem[0]);
        if (index > -1) {
            this.eligibilityData.splice(index);
        }
    }

    @Action({ rawError: true })
    public async clearStore() {
        this.updateEligibilityData([]);
        this.updateIsEligible(false);
    }

    @Action({ rawError: true })
    public checkEligibility(eligibilityData: SurveySessionValueModel[]): boolean {
        if (localStorage.getItem('hyrimozPathName') === 'patient') {
            const correctAnswers = [
                +this.settings.eligibilityFederalAnswer,
                +this.settings.eligibilityPrescribedAnswer,
                +this.settings.eligibilityCommercialAnswer,
                +this.settings.eligibilityTermConditionAnswer,
            ] as number[];
            const isEligible = eligibilityData.every(question => question.answerId && correctAnswers.includes(question.answerId));
            this.updateIsEligible(isEligible);
            return isEligible;
        } else if (localStorage.getItem('hyrimozPathName') === 'patient-transition') {
            const correctAnswers = [
                +this.settings.eligibilityAgeAnswerTransition,
                +this.settings.eligibilityFederalAnswerTransition,
                +this.settings.eligibilityPrescribedAnswerTransition,
                +this.settings.eligibilityCommercialAnswerTransition,
                +this.settings.eligibilityTermConditionAnswerTransition,
                +this.settings.eligibilityReimbursementAnswerTransition,
            ] as number[];
            const isEligible = eligibilityData.every(question => question.answerId && correctAnswers.includes(question.answerId));
            this.updateIsEligible(isEligible);
            return isEligible;
        } else {
            const correctAnswers = [
                +this.settings.eligibilityFederalAnswerProviderPharmacy,
                +this.settings.eligibilityPrescribedAnswerProviderPharmacy,
                +this.settings.eligibilityCommercialAnswerProviderPharmacy,
                +this.settings.eligibilityTermConditionAnswerProviderPharmacy,
            ] as number[];
            const isEligible = eligibilityData.every(question => question.answerId && correctAnswers.includes(question.answerId));
            this.updateIsEligible(isEligible);
            return isEligible;
        }
    }
}
