import Vue from 'vue';
import { Resolve } from 'vue-di';
import { InjectModule, InjectVuexModule } from 'vue-di/vuex';
import {Settings} from "~/services/settings";
import LayoutModule from "~/store/layout";
import EnrollmentModule from '~/store/enrollment';
import moment, { Moment } from 'moment';
import states from '~/assets/states';
import PdfGenerationModule from '~/store/pdfGeneration';
import RouteModule from '~/store/routes';

@InjectModule({ stateFactory: true }, module)
export default class prepareDocumentMoreServicesBlob extends InjectVuexModule {
@Resolve
public layout!: LayoutModule;

@Resolve
public settings!: Settings;

@Resolve
public enrollment!: EnrollmentModule;

@Resolve
pdfGeneration!: PdfGenerationModule;

@Resolve
routes!: RouteModule;

public states = states;

async prepareDocumentBlob() {
    const pdfObjectIjectionTraining = [[
        { sr: 1 ,type: 'info', label: '', value: ""},
        { sr: 2 ,type: 'title', label: 'Injection Training Request', value: '' },
        { sr: 1 ,type: 'info', label: '', value: ""},
        { sr: 2 ,type: 'info', label: '', value: ""},
        { sr: 3 ,type: 'info', label: 'Patient First Name:', value: this.enrollment.InjectionTrainingData.firstName || '' },
        { sr: 4 ,type: 'info', label: 'Patient Last Name:', value: this.enrollment.InjectionTrainingData.lastName || '' },
        { sr: 5 ,type: 'info', label: 'Patient Date of Birth:', value: this.enrollment.InjectionTrainingData.dateOfBirth || '' },
        { sr: 6 ,type: 'info', label: 'Patient Home Phone:', value: this.enrollment.InjectionTrainingData.homePhone || '' },
        { sr: 7 ,type: 'info', label: 'Patient Mobile Phone:', value: this.enrollment.InjectionTrainingData.phone || '' },
        { sr: 8 ,type: 'info', label: 'Prefered method of training:', value: this.enrollment.InjectionTrainingData.selectPreferedMethod || '' },
        { sr: 9 ,type: 'info', label: 'Preferred Date:', value: this.enrollment.InjectionTrainingData.preferedDate1 || '' },
        { sr: 10 ,type: 'info', label: 'Preferred Time:', value: this.enrollment.InjectionTrainingData.preferredTimeSlot1 || '' },
        { sr: 11 ,type: 'info', label: 'Preferred Date:', value: this.enrollment.InjectionTrainingData.preferedDate2 || '' },
        { sr: 12 ,type: 'info', label: 'Preferred Time:', value: this.enrollment.InjectionTrainingData.preferredTimeSlot2 || '' },
        { sr: 13 ,type: 'info', label: 'Preferred Date:', value: this.enrollment.InjectionTrainingData.preferedDate3 || '' },
        { sr: 14 ,type: 'info', label: 'Preferred Time:', value: this.enrollment.InjectionTrainingData.preferredTimeSlot3 || '' },
    ]];

    const pdfObjectNurseAmbassadorTraining = [[
        { sr: 1 ,type: 'info', label: '', value: ""},
        { sr: 2 ,type: 'title', label: 'Nurse Ambassador Phone Call Request', value: '' },
        { sr: 1 ,type: 'info', label: '', value: ""},
        { sr: 2 ,type: 'info', label: '', value: ""},
        { sr: 3 ,type: 'info', label: 'Patient First Name:', value: this.enrollment.nursingData.firstName || '' },
        { sr: 4 ,type: 'info', label: 'Patient Last Name:', value: this.enrollment.nursingData.lastName || '' },
        { sr: 5 ,type: 'info', label: 'Patient Date of Birth:', value: this.enrollment.nursingData.dateOfBirth || '' },
        { sr: 6 ,type: 'info', label: 'Patient Home Phone:', value: this.enrollment.nursingData.homePhone || '' },
        { sr: 7 ,type: 'info', label: 'Patient Mobile Phone:', value: this.enrollment.nursingData.phone || '' },
        { sr: 1 ,type: 'info', label: '', value: ""},
        { sr: 9 ,type: 'info', label: 'Preferred Date:', value: this.enrollment.nursingData.Date1 || '' },
        { sr: 10 ,type: 'info', label: 'Preferred Time:', value: this.enrollment.nursingData.timeSlote1 || '' },
        { sr: 11 ,type: 'info', label: 'Preferred Date:', value: this.enrollment.nursingData.Date2 || '' },
        { sr: 12 ,type: 'info', label: 'Preferred Time:', value: this.enrollment.nursingData.timeSlote2 || '' },
        { sr: 13 ,type: 'info', label: 'Preferred Date:', value: this.enrollment.nursingData.Date3 || '' },
        { sr: 14 ,type: 'info', label: 'Preferred Time:', value: this.enrollment.nursingData.timeSlote3 || '' },
    ]];

    const pdfObjectWelcomeKitRequest = [[
        { sr: 1 ,type: 'info', label: '', value: ""},
        { sr: 2 ,type: 'title', label: 'Welcome Kit Request', value: '' },
        { sr: 1 ,type: 'info', label: '', value: ""},
        { sr: 2 ,type: 'info', label: '', value: ""},
        { sr: 3 ,type: 'info', label: 'Patient First Name:', value: this.enrollment.welcomeKitData.firstName || '' },
        { sr: 4 ,type: 'info', label: 'Patient Last Name:', value: this.enrollment.welcomeKitData.lastName || '' },
        { sr: 9 ,type: 'info', label: 'Patient Address Line 1:', value: this.enrollment.welcomeKitData.addressOne || ''},
        { sr: 19 ,type: 'info', label: 'Patient Address Line 2:', value: this.enrollment.welcomeKitData.addressTwo || ''},
        { sr: 5 ,type: 'info', label: 'Patient Date of Birth:', value: this.enrollment.welcomeKitData.dateOfBirth || '' },
        { sr: 10 ,type: 'info', label: 'Patient Zip Code:', value: this.enrollment.welcomeKitData.zip.toString() || ''},
        { sr: 11 ,type: 'info', label: 'Patient City:', value: this.enrollment.welcomeKitData.city},
        { sr: 12 ,type: 'info', label: 'Patient State:', value: this.capitalizeFirstLetter(states.find(i => i.code === this.enrollment.welcomeKitData.state)?.description || "")},
        { sr: 6 ,type: 'info', label: 'Patient Home Phone:', value: this.enrollment.welcomeKitData.homePhone || '' },
        { sr: 7 ,type: 'info', label: 'Patient Mobile Phone:', value: this.enrollment.welcomeKitData.phone || '' },
        { sr: 1 ,type: 'info', label: '', value: ""},
        { sr: 16 ,type: 'checkbox', label: 'I have read and agree to receive text messages and calls as explained in the Telephone Consumer Protection Act (TCPA) consent (optional).', value: this.enrollment.welcomeKitData.consentCheckbox ? "1" :"0"},
        { sr: 17 ,type: 'checkbox', label: 'I have read and agree to the Terms and Conditions for participation in the Sandoz Co-Pay Assistance Program.', value: this.enrollment.welcomeKitData.tncCheckbox ? "1" :"0"},
        { sr: 18 ,type: 'checkbox', label: 'I have read and agree to the Sandoz Patient Assistance program (SPA) and Fair Credit Reporting Act Authorization.', value: this.enrollment.welcomeKitData.fcraCheckbox ? "1" :"0"},
        { sr: 19 ,type: 'checkbox', label: 'I have read and agree to receive text messages and calls as explained in the Telephone Consumer Protection Act (TCPA) consent (optional).', value: this.enrollment.welcomeKitData.tcpaConsentCheckbox ? "1" :"0"},
        { sr: 15 ,type: 'info', label: '', value: ""},
        { sr: 20 ,type: 'info', label: 'Parent/Legal Guardian Signature Name:',value: this.enrollment.welcomeKitData.signName|| ""},
        { sr: 21 ,type: 'info', label: 'Signature Date:', value: this.getSignDate()|| ""},
    ]];

    const pdfObjectSharpsContainerRequest = [[
        { sr: 1 ,type: 'info', label: '', value: ""},
        { sr: 2 ,type: 'title', label: 'Sharps Container Request', value: '' },
        { sr: 1 ,type: 'info', label: '', value: ""},
        { sr: 2 ,type: 'info', label: '', value: ""},
        { sr: 3 ,type: 'info', label: 'Patient First Name:', value: this.enrollment.sharpsContainerData.firstName || '' },
        { sr: 4 ,type: 'info', label: 'Patient Last Name:', value: this.enrollment.sharpsContainerData.lastName || '' },
        { sr: 9 ,type: 'info', label: 'Patient Address Line 1:', value: this.enrollment.sharpsContainerData.addressOne || ''},
        { sr: 19 ,type: 'info', label: 'Patient Address Line 2:', value: this.enrollment.sharpsContainerData.addressTwo || ''},
        { sr: 5 ,type: 'info', label: 'Patient Date of Birth:', value: this.enrollment.sharpsContainerData.dateOfBirth || '' },
        { sr: 10 ,type: 'info', label: 'Patient Zip Code:', value: this.enrollment.sharpsContainerData.zip.toString() || ''},
        { sr: 11 ,type: 'info', label: 'Patient City:', value: this.enrollment.sharpsContainerData.city},
        { sr: 12 ,type: 'info', label: 'Patient State:', value: this.capitalizeFirstLetter(states.find(i => i.code === this.enrollment.sharpsContainerData.state)?.description || "")},
        { sr: 6 ,type: 'info', label: 'Patient Home Phone:', value: this.enrollment.sharpsContainerData.homePhone || '' },
        { sr: 7 ,type: 'info', label: 'Patient Mobile Phone:', value: this.enrollment.sharpsContainerData.phone || '' },
        { sr: 1 ,type: 'info', label: '', value: ""},
        { sr: 16 ,type: 'checkbox', label: 'I have read and agree to receive text messages and calls as explained in the Telephone Consumer Protection Act (TCPA) consent (optional).', value: this.enrollment.sharpsContainerData.consentCheckbox ? "1" :"0"},
        { sr: 17 ,type: 'checkbox', label: 'I have read and agree to the Terms and Conditions for participation in the Sandoz Co-Pay Assistance Program.', value: this.enrollment.sharpsContainerData.tncCheckbox ? "1" :"0"},
        { sr: 18 ,type: 'checkbox', label: 'I have read and agree to the Sandoz Patient Assistance program (SPA) and Fair Credit Reporting Act Authorization.', value: this.enrollment.sharpsContainerData.fcraCheckbox ? "1" :"0"},
        { sr: 19 ,type: 'checkbox', label: 'I have read and agree to receive text messages and calls as explained in the Telephone Consumer Protection Act (TCPA) consent (optional).', value: this.enrollment.sharpsContainerData.tcpaConsentCheckbox ? "1" :"0"},
        { sr: 15 ,type: 'info', label: '', value: ""},
        { sr: 20 ,type: 'info', label: 'Parent/Legal Guardian Signature Name:',value: this.enrollment.sharpsContainerData.signName|| ""},
        { sr: 21 ,type: 'info', label: 'Signature Date:', value: this.getSignDate()|| ""},
    ]];

    let selectedService = this.enrollment.patientSelectedServices.filter((selectedService) => {
        if(selectedService !== '') return selectedService;
        else return;
    })
    selectedService.pop();

    // console.log(selectedService);
    // console.log(selectedService.length);
    let combineObject: { sr: number; type: string; label: string; value: string; }[][] = [];
    if(selectedService.length===4)
    {
        //console.log("All Selected");
        combineObject = [...pdfObjectIjectionTraining, ...pdfObjectWelcomeKitRequest, ...pdfObjectSharpsContainerRequest, ...pdfObjectNurseAmbassadorTraining ];
        if(window.location.href.includes('nurse-ambassador') ) {
            const result = await this.pdfGeneration.uploadPdf(combineObject);
            return  result.status;
        }
    }
    else if(selectedService.length===3){
        //console.log("3 Selected");
        if(selectedService.includes("patient-injection-training")){
            //injection
            if(selectedService.includes("patient-welcome-kit")){
                //injection, welcome kit Nurse
                if(selectedService.includes("patient-nurse-ambassador")){
                    //injection, welcome kit, Nurse Ambassador
                    combineObject = [...pdfObjectIjectionTraining, ...pdfObjectWelcomeKitRequest, ...pdfObjectNurseAmbassadorTraining];
                    if(window.location.href.includes('nurse-ambassador') ) {
                        const result = await this.pdfGeneration.uploadPdf(combineObject);
                        return  result.status;
                    }
                }
                else{
                    //injection, welcome kit, Sharp Container
                    combineObject = [...pdfObjectIjectionTraining, ...pdfObjectWelcomeKitRequest, ...pdfObjectSharpsContainerRequest];
                    if(window.location.href.includes('sharps-container') ) {
                        const result = await this.pdfGeneration.uploadPdf(combineObject);
                        return  result.status;
                    }
                }
            }
            else{
                    //injection, Nurse Ambassador , Sharp Container
                combineObject = [...pdfObjectIjectionTraining, ...pdfObjectSharpsContainerRequest, ...pdfObjectNurseAmbassadorTraining];
                if(window.location.href.includes('nurse-ambassador') ) {
                    const result = await this.pdfGeneration.uploadPdf(combineObject);
                    return  result.status;
                }
            }
        }
        else{
            //welcome kit, Nurse Ambassador , Sharp Container
            combineObject = [...pdfObjectWelcomeKitRequest, ...pdfObjectSharpsContainerRequest, ...pdfObjectNurseAmbassadorTraining];
            if(window.location.href.includes('nurse-ambassador') ) {
                const result = await this.pdfGeneration.uploadPdf(combineObject);
                return  result.status;
            }
        }
    }
    else if(selectedService.length===2){
        //console.log("2 Selected");
        if(selectedService.includes("patient-injection-training")){
            //injection
            if(selectedService.includes("patient-welcome-kit")){
                //injection, welcome kit
                combineObject = [...pdfObjectIjectionTraining, ...pdfObjectWelcomeKitRequest];
                if(window.location.href.includes('welcome-kit') ) {
                    const result = await this.pdfGeneration.uploadPdf(combineObject);
                    return  result.status;
                }
            }
            else{
                if(selectedService.includes("patient-nurse-ambassador")){
                    //injection,  Nurse Ambassador
                    combineObject = [...pdfObjectIjectionTraining, ...pdfObjectNurseAmbassadorTraining];
                    if(window.location.href.includes('nurse-ambassador') ) {
                        const result = await this.pdfGeneration.uploadPdf(combineObject);
                        return  result.status;
                    }
                }
                else{
                    //injectio, Sharp Container
                    combineObject = [...pdfObjectIjectionTraining, ...pdfObjectSharpsContainerRequest];
                    if(window.location.href.includes('sharps-container') ) {
                        const result = await this.pdfGeneration.uploadPdf(combineObject);
                        return  result.status;
                    }
                }

            }
        }
        else{
            if(selectedService.includes("patient-welcome-kit")){
                //welcome kit
                if(selectedService.includes("patient-sharps-container")){
                    //welcome kit, Sharp Container
                    combineObject = [...pdfObjectWelcomeKitRequest, ...pdfObjectSharpsContainerRequest];
                    if(window.location.href.includes('sharps-container') ) {
                        const result = await this.pdfGeneration.uploadPdf(combineObject);
                        return  result.status;
                    }
                }
                else{
                    //welcome kit, Nurse Ambassador
                    combineObject = [...pdfObjectWelcomeKitRequest, ...pdfObjectNurseAmbassadorTraining];
                    if(window.location.href.includes('nurse-ambassador') ) {
                        const result = await this.pdfGeneration.uploadPdf(combineObject);
                        return  result.status;
                    }
                }
            }
            else{
                //Sharp Container, Nurse Ambassador
                combineObject = [...pdfObjectSharpsContainerRequest, ...pdfObjectNurseAmbassadorTraining];
                if(window.location.href.includes('nurse-ambassador') ) {
                    const result = await this.pdfGeneration.uploadPdf(combineObject);
                    return  result.status;
                }
            }

        }

    }
    else{
        //console.log("1 Selected");
        if(selectedService.includes("patient-injection-training")){
            const result = await this.pdfGeneration.uploadPdf(pdfObjectIjectionTraining);
            //console.log(result.status);
            return  result.status;
        }
        else if(selectedService.includes("patient-welcome-kit")){
            const result = await this.pdfGeneration.uploadPdf(pdfObjectWelcomeKitRequest);
            return  result.status;
        }
        else if(selectedService.includes("patient-sharps-container")){
            const result = await this.pdfGeneration.uploadPdf(pdfObjectSharpsContainerRequest);
            return  result.status;
        }
        else{
            const result = await this.pdfGeneration.uploadPdf(pdfObjectNurseAmbassadorTraining);
            return  result.status;
        }
    }
    return 'no-condition';
  }

  public getSignDate() {
    return moment().format('MM/DD/YYYY');
    }
  capitalizeFirstLetter(string: string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
    }
}
