
export default function(ctx: any) {
    if((/true/i).test(ctx.env.vanityHUBEnrollmentPath) && !ctx.route.path.includes("/patient/") 
        && !ctx.route.path.includes("/error") && !ctx.route.path.includes("/rejection")) {
        if(ctx.route.path === '/' ) {
            ctx.redirect({ name: 'provider-patient-information' });
        } else if ( ctx.route.path.includes("/provider/") ) {
            return true
        } else {
          ctx.redirect({ name: 'index'});
        }
        return;
    } else {
        return true
    }
}
