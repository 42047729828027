const middleware = {}

middleware['globaliri'] = require('..\\middleware\\globaliri.ts')
middleware['globaliri'] = middleware['globaliri'].default || middleware['globaliri']

middleware['hideiri'] = require('..\\middleware\\hideiri.ts')
middleware['hideiri'] = middleware['hideiri'].default || middleware['hideiri']

middleware['session'] = require('..\\middleware\\session.ts')
middleware['session'] = middleware['session'].default || middleware['session']

middleware['showiri'] = require('..\\middleware\\showiri.ts')
middleware['showiri'] = middleware['showiri'].default || middleware['showiri']

middleware['tc-password-protect'] = require('..\\middleware\\tc-password-protect.ts')
middleware['tc-password-protect'] = middleware['tc-password-protect'].default || middleware['tc-password-protect']

middleware['vanity-site-hub-protect'] = require('..\\middleware\\vanity-site-hub-protect.ts')
middleware['vanity-site-hub-protect'] = middleware['vanity-site-hub-protect'].default || middleware['vanity-site-hub-protect']

export default middleware
