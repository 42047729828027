



















import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { Resolve } from 'vue-di';
import LayoutModule from '~/store/layout';
import EnrollmentModule from '~/store/enrollment';

@Component<TransitionBox>({})
export default class TransitionBox extends Vue {
    @Resolve
    public layout!: LayoutModule;
    @Prop()
    public brkPoints!: object;
    @Prop({ default: false })
    public isFixed!: boolean;

    @Resolve
    public enrollment!: EnrollmentModule;

    public get footerInfoLinkLocation() {
        return this.layout.configuration.footerInfoLinkLocation;
    }
}
