import Vue from 'vue';
import { Resolve } from 'vue-di';
import { InjectModule, InjectVuexModule } from 'vue-di/vuex';
import moment, { Moment } from 'moment';
import { PDFForm } from 'pdf-lib';
import {Settings} from "~/services/settings";
import LayoutModule from "~/store/layout";
import EnrollmentModule from '~/store/enrollment';
import states from '~/assets/states';
import PdfGenerationModule from '~/store/pdfGeneration';
import RouteModule from '~/store/routes';

@InjectModule({ stateFactory: true }, module)
export default class hcpPDFMapping extends InjectVuexModule {
@Resolve
public layout!: LayoutModule;

@Resolve
public settings!: Settings;

@Resolve
public enrollment!: EnrollmentModule;

    getdateOfBirthOfPatient(value: any) {
        return moment(String(value)).format('MM/DD/YYYY');
    }
    getMonthOnly(value: any){
    const completeDate = moment(String(value)).format('MM/DD/YYYY');
    const month = completeDate.slice(0,2);
    return month;
    }
    getDateOnly(value: any){
    const completeDate = moment(String(value)).format('MM/DD/YYYY');
    const date = completeDate.slice(3,5);
    return date;
    }
    getYearOnly(value: any){
    const completeDate = moment(String(value)).format('MM/DD/YYYY');
    const year = completeDate.slice(6,10);
    return year;
    }

    patientIsJuvenile() {
        return this.enrollment.currentJuvinileStatus === "Yes";
    }

    capitalizeFirstLetter(string: string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    public fieldMappingToPDFAdult(form: PDFForm){
        if(!this.patientIsJuvenile()){
        //Patient-Information-Adult
        if(this.enrollment.patientHcpInformationData.patientIs==="New to Hyrimoz") form.getCheckBox("New").check();
        if(this.enrollment.patientHcpInformationData.patientIs==="Switching from other brand to Hyrimoz") form.getCheckBox("Switch").check();
        if(this.enrollment.patientHcpInformationData.patientIs==="Restarting Hyrimoz") form.getCheckBox("Restart").check();
         //Select Services
        if(this.enrollment.patientHcpInformationData.allServices) form.getCheckBox("All Services OR (select desired services)").check();
        if(this.enrollment.patientHcpInformationData.benefitsAuthorization) form.getCheckBox("Benefits Investigation").check();
        if(this.enrollment.patientHcpInformationData.authorizationSupport) form.getCheckBox("Prior Authorization/Appeal Support").check();
        if(this.enrollment.patientHcpInformationData.quickStart) form.getCheckBox("QuickStart Program").check();
        if(this.enrollment.patientHcpInformationData.CopayProgram) form.getCheckBox("Co-Pay Program").check();
        if(this.enrollment.patientHcpInformationData.financialAssistance) form.getCheckBox("Other Financial Assistance").check();
        if(this.enrollment.patientHcpInformationData.starterKit) form.getCheckBox("Welcome Packet").check();
        if(this.enrollment.patientHcpInformationData.injectionTraining) form.getCheckBox("Injection Training").check();
        //Patient Information
        form.getTextField('First Name').setText(this.enrollment.patientHcpInformationData.firstName || '');
        form.getTextField('Last Name').setText(this.enrollment.patientHcpInformationData.lastName || '');
        form.getTextField('DB1').setText(this.enrollment.patientHcpInformationData.dateOfBirth ? this.getMonthOnly(this.enrollment.patientHcpInformationData.dateOfBirth) : '');
        form.getTextField('DB2').setText(this.enrollment.patientHcpInformationData.dateOfBirth ? this.getDateOnly(this.enrollment.patientHcpInformationData.dateOfBirth) : '');
        form.getTextField('DB3').setText(this.enrollment.patientHcpInformationData.dateOfBirth ? this.getYearOnly(this.enrollment.patientHcpInformationData.dateOfBirth) : '');
        if(this.enrollment.patientHcpInformationData.gender === 'Male') form.getCheckBox("M").check();
        if(this.enrollment.patientHcpInformationData.gender === 'Female') form.getCheckBox("F").check();
        form.getTextField('Address').setText(this.enrollment.patientHcpInformationData.addressOne || '');
        form.getTextField('City').setText(this.enrollment.patientHcpInformationData.city || '');
        form.getTextField('State').setText(this.capitalizeFirstLetter(states.find(i => i.code === this.enrollment.patientHcpInformationData.state)?.description || "") || '');
        form.getTextField('Zip').setText(this.enrollment.patientHcpInformationData.zip || '');
        form.getTextField('Home Phone').setText(this.enrollment.patientHcpInformationData.homePhone || '');
        form.getTextField('Moblie Phone').setText(this.enrollment.patientHcpInformationData.phone || '');
        if(this.enrollment.patientHcpInformationData.hearing === 'Interpreter Needed') form.getCheckBox("Interpreter Needed").check();
        if(this.enrollment.patientHcpInformationData.hearing === 'Hearing Impaired') form.getCheckBox("Hearing Impaired").check();
        form.getTextField('Email').setText(this.enrollment.patientHcpInformationData.email || '');
        form.getTextField('Language').setText(this.enrollment.patientHcpInformationData.Language || '');
        //health-insurence-adult or Insurance Information
        if(this.enrollment.healthInsuranceData.documentsIncluded) form.getCheckBox("Documents Included").check();
        if(this.enrollment.healthInsuranceData.privateCommercial) form.getCheckBox("Commercial/Private").check();
        if(this.enrollment.healthInsuranceData.medicareCheckbox) form.getCheckBox("Medicare").check();
        if(this.enrollment.healthInsuranceData.govFundCheckbox) form.getCheckBox("Medicaid, Government-Funded Plan, or VA").check();
        if(this.enrollment.healthInsuranceData.notInsuredCheckbox) form.getCheckBox("Not Insured").check();
        form.getTextField('Beneficiary').setText(this.enrollment.healthInsuranceData.medicalBeneficiaryName || '');
        form.getTextField('Prescription Insurance').setText(this.enrollment.healthInsuranceData.prescriptionInsuranceCarrier || '');
        form.getTextField('Medical Insurance').setText(this.enrollment.healthInsuranceData.medicalInsuranceCarrier || '');
        form.getTextField('Med Insurance ID').setText(this.enrollment.healthInsuranceData.medicalPolicy || '');
        form.getTextField('Group Number').setText(this.enrollment.healthInsuranceData.medicalGroup || '');
        form.getTextField('RX Group').setText(this.enrollment.healthInsuranceData.rxGroup || '');
        form.getTextField('RX ID').setText(this.enrollment.healthInsuranceData.rxId || '');
        form.getTextField('RX BIN').setText(this.enrollment.healthInsuranceData.rxBin || '');
        form.getTextField('RX PCN').setText(this.enrollment.healthInsuranceData.rxPcn || '');
        //Diagnose-information-adult 
        if(this.enrollment.DiagnoseData.RheumatoidArthritis) form.getCheckBox("Rheumatoid Arthritis (RA)").check();
        if(this.enrollment.DiagnoseData.PsoriaticArthritis) form.getCheckBox("Psoriatic Arthritis (PsA)").check();
        if(this.enrollment.DiagnoseData.AnkylosingSpondylitis) form.getCheckBox("Ankylosing Spondylitis (AS)").check();
        if(this.enrollment.DiagnoseData.CrohnsDisease) form.getCheckBox("Crohn’s Disease (CD)").check();
        if(this.enrollment.DiagnoseData.UlcerativeColitis) form.getCheckBox("Ulcerative Colitis (UC)").check();
        if(this.enrollment.DiagnoseData.PlaquePsoriasis) form.getCheckBox("Plaque Psoriasis (Ps)").check();
        if(this.enrollment.DiagnoseData.HidradenitisSuppurativa) form.getCheckBox("Hidradenitis Suppurativa (HS)").check();
        if(this.enrollment.DiagnoseData.Uveitis) form.getCheckBox("Uveitis (UV)").check();
        //prescriber-information-adult
        form.getTextField('Prescribers Name').setText((this.enrollment.hcpPrescriberInformation.firstName + ' '+this.enrollment.hcpPrescriberInformation.lastName) || '');
        form.getTextField('Office Contact Name').setText(this.enrollment.hcpPrescriberInformation.officeContactName || '');
        form.getTextField('Site Name').setText(this.enrollment.hcpPrescriberInformation.siteName || '');
        form.getTextField('Site Address').setText(this.enrollment.hcpPrescriberInformation.address || '');
        form.getTextField('Site City').setText(this.enrollment.hcpPrescriberInformation.city || '');
        form.getTextField('Site State').setText(this.capitalizeFirstLetter(states.find(i => i.code === this.enrollment.hcpPrescriberInformation.state)?.description || "") || '');
        form.getTextField('Site Zip').setText(this.enrollment.hcpPrescriberInformation.zip || '');
        form.getTextField('Office Phone').setText(this.enrollment.hcpPrescriberInformation.phone || '');
        form.getTextField('Office Fax').setText(this.enrollment.hcpPrescriberInformation.fax || '');
        form.getTextField('NPI').setText(this.enrollment.hcpPrescriberInformation.npi || '');
        //Pharmacy-prescription-adult
        form.getTextField('Preferred Pharmacy').setText(this.enrollment.pharmacyPrescriptionData.preferredSpecialty || '');
        if(this.enrollment.pharmacyPrescriptionData.specialtyPharmacy) form.getCheckBox("Do Not Send to Specialty Pharmacy").check();
        //Rheumatoid Table
        if(this.enrollment.RheumatoidArthritisData.rheumatoidColumn11) form.getCheckBox("HYRIMOZ Sensoready® Pen 40 mg/0.4 mLa").check();
        if(this.enrollment.RheumatoidArthritisData.rheumatoidColumn21) form.getCheckBox("Inject 40 mg SC every other week").check();
        if(this.enrollment.RheumatoidArthritisData.rheumatoidColumn31) form.getCheckBox("1 month").check();
        if(this.enrollment.RheumatoidArthritisData.rheumatoidColumn32) form.getCheckBox("3 months").check();
        form.getTextField('Refills').setText(this.enrollment.RheumatoidArthritisData.refills || '');
        if(this.enrollment.RheumatoidArthritisData.rheumatoidColumn12) form.getCheckBox("HYRIMOZ Prefilled Syringe 40 mg/04 mLa").check();
        if(this.enrollment.RheumatoidArthritisData.rheumatoidColumn22) form.getCheckBox("Inject 40 mg SC every weekb").check();
        if(this.enrollment.RheumatoidArthritisData.rheumatoidColumn13) form.getCheckBox("HYRIMOZ Sensoready Pen 80 mg/0.8 mL (RA only)").check();
        if(this.enrollment.RheumatoidArthritisData.rheumatoidColumn23) form.getCheckBox("Inject 80 mg SC every other weekb").check();
        if(this.enrollment.RheumatoidArthritisData.rheumatoidColumn14) form.getCheckBox("HYRIMOZ Prefilled Syringe 80 mg/08 mL (RA only)").check();
        //Crohn Table
        if(this.enrollment.CrohnsDiseasOrUlcerativeColitisData.CrohnsDiseasOrUlcerativeColitis11) form.getCheckBox("HYRIMOZ Sensoready Pen Starter Pack,").check();
        if(this.enrollment.CrohnsDiseasOrUlcerativeColitisData.CrohnsDiseasOrUlcerativeColitis21) form.getCheckBox("Inject 160 mg SC on Day 1 (given in one day or split over two consecutive").check();
        if(this.enrollment.CrohnsDiseasOrUlcerativeColitisData.CrohnsDiseasOrUlcerativeColitis31) form.getCheckBox("1 kit (Carton of 3)").check();
        if(this.enrollment.CrohnsDiseasOrUlcerativeColitisData.CrohnsDiseasOrUlcerativeColitis12) form.getCheckBox("HYRIMOZ Sensoready Pen 40 mg/04 mLa").check();
        if(this.enrollment.CrohnsDiseasOrUlcerativeColitisData.CrohnsDiseasOrUlcerativeColitis32) form.getCheckBox("1 month_1").check();
        if(this.enrollment.CrohnsDiseasOrUlcerativeColitisData.CrohnsDiseasOrUlcerativeColitis33) form.getCheckBox("3 months_1").check();
        form.getTextField('Refills_1').setText(this.enrollment.CrohnsDiseasOrUlcerativeColitisData.refills32 || '');
        if(this.enrollment.CrohnsDiseasOrUlcerativeColitisData.CrohnsDiseasOrUlcerativeColitis13) form.getCheckBox("HYRIMOZ Prefilled Syringe 40 mg/04 mLa_1").check();
        if(this.enrollment.CrohnsDiseasOrUlcerativeColitisData.CrohnsDiseasOrUlcerativeColitis14) form.getCheckBox("HYRIMOZ Sensoready Pen 40 mg/04 mLa_1").check();
        if(this.enrollment.CrohnsDiseasOrUlcerativeColitisData.CrohnsDiseasOrUlcerativeColitis22) form.getCheckBox("Inject 40 mg SC every other week starting on Day 29").check();
        if(this.enrollment.CrohnsDiseasOrUlcerativeColitisData.CrohnsDiseasOrUlcerativeColitis34) form.getCheckBox("1 month_2").check();
        if(this.enrollment.CrohnsDiseasOrUlcerativeColitisData.CrohnsDiseasOrUlcerativeColitis35) form.getCheckBox("3 months_2").check();
        form.getTextField('Refills_2').setText(this.enrollment.CrohnsDiseasOrUlcerativeColitisData.refills33 || '');
        if(this.enrollment.CrohnsDiseasOrUlcerativeColitisData.CrohnsDiseasOrUlcerativeColitis15) form.getCheckBox("HYRIMOZ Prefilled Syringe 40 mg/04 mLa_2").check();
        form.getTextField('Patient Name').setText((this.enrollment.patientHcpInformationData.firstName + ' '+this.enrollment.patientHcpInformationData.lastName) || '');
    
        //Plaque Table
        if(this.enrollment.PlaquePsoriasisData.PlaquePsoriasis11) form.getCheckBox("HYRIMOZ Sensoready® Pen Starter Pack,").check();
        if(this.enrollment.PlaquePsoriasisData.PlaquePsoriasis21) form.getCheckBox("Inject 80 mg SC on Day 1, followed by 40 mg SC every other week").check();
        if(this.enrollment.PlaquePsoriasisData.PlaquePsoriasis31) form.getCheckBox("1 kit (Carton of 3").check();
        if(this.enrollment.PlaquePsoriasisData.PlaquePsoriasis12) form.getCheckBox("HYRIMOZ Sensoready Pen 40 mg/04 mLa_2").check();
        if(this.enrollment.PlaquePsoriasisData.PlaquePsoriasis32) form.getCheckBox("1 month_3").check();
        if(this.enrollment.PlaquePsoriasisData.PlaquePsoriasis33) form.getCheckBox("3 months_3").check();
        form.getTextField('Refills_3').setText(this.enrollment.PlaquePsoriasisData.refills32 || '');
        if(this.enrollment.PlaquePsoriasisData.PlaquePsoriasis13) form.getCheckBox("HYRIMOZ Prefilled Syringe 40 mg/04 mLa_3").check();
        if(this.enrollment.PlaquePsoriasisData.PlaquePsoriasis14) form.getCheckBox("HYRIMOZ Sensoready Pen 40 mg/04 mLa_3").check();
        if(this.enrollment.PlaquePsoriasisData.PlaquePsoriasis22) form.getCheckBox("Inject 40 mg SC every other week_1").check();
        if(this.enrollment.PlaquePsoriasisData.PlaquePsoriasis34) form.getCheckBox("1 month_4").check();
        if(this.enrollment.PlaquePsoriasisData.PlaquePsoriasis35) form.getCheckBox("3 months_4").check();
        form.getTextField('Refills_4').setText(this.enrollment.PlaquePsoriasisData.refills33 || '');
        if(this.enrollment.PlaquePsoriasisData.PlaquePsoriasis15) form.getCheckBox("HYRIMOZ Prefilled Syringe 40 mg/04 mLa_4").check();
        //Hidradenitis Table
        if(this.enrollment.HidradenitisSuppurativaData.HidradenitisSuppurativa11) form.getCheckBox("HYRIMOZ Sensoready Pen Starter Pack,_1").check();
        if(this.enrollment.HidradenitisSuppurativaData.HidradenitisSuppurativa21) form.getCheckBox("Inject 160 mg SC on Day 1 (given in one day or split over two consecutive_1").check();
        if(this.enrollment.HidradenitisSuppurativaData.HidradenitisSuppurativa31) form.getCheckBox("1 kit (Carton of 3)_1").check();
        if(this.enrollment.HidradenitisSuppurativaData.HidradenitisSuppurativa12) form.getCheckBox("HYRIMOZ Sensoready Pen 40 mg/04 mLa_4").check();
        if(this.enrollment.HidradenitisSuppurativaData.HidradenitisSuppurativa32) form.getCheckBox("1 month_5").check();
        if(this.enrollment.HidradenitisSuppurativaData.HidradenitisSuppurativa33) form.getCheckBox("3 months_5").check();
        form.getTextField('Refills_5').setText(this.enrollment.HidradenitisSuppurativaData.refills32 || '');
        if(this.enrollment.HidradenitisSuppurativaData.HidradenitisSuppurativa13) form.getCheckBox("HYRIMOZ Prefilled Syringe 40 mg/04 mLa_5").check();
        if(this.enrollment.HidradenitisSuppurativaData.HidradenitisSuppurativa14) form.getCheckBox("HYRIMOZ Sensoready Pen 40 mg/04 mLa_5").check();
        if(this.enrollment.HidradenitisSuppurativaData.HidradenitisSuppurativa22) form.getCheckBox("Inject 40 mg SC every week starting on Day 29").check();
        if(this.enrollment.HidradenitisSuppurativaData.HidradenitisSuppurativa34) form.getCheckBox("1 month_6").check();
        if(this.enrollment.HidradenitisSuppurativaData.HidradenitisSuppurativa35) form.getCheckBox("3 months_6").check();
        form.getTextField('Refills_6').setText(this.enrollment.HidradenitisSuppurativaData.refills33 || '');
        if(this.enrollment.HidradenitisSuppurativaData.HidradenitisSuppurativa15) form.getCheckBox("HYRIMOZ Prefilled Syringe 40 mg/0.4 mLa").check();
        if(this.enrollment.HidradenitisSuppurativaData.HidradenitisSuppurativa16) form.getCheckBox("HYRIMOZ Sensoready Pen 80 mg/08 mL").check();
        if(this.enrollment.HidradenitisSuppurativaData.HidradenitisSuppurativa23) form.getCheckBox("Inject 80 mg SC every other week starting on Day 29").check();
        if(this.enrollment.HidradenitisSuppurativaData.HidradenitisSuppurativa17) form.getCheckBox("HYRIMOZ Prefilled Syringe 80 mg/08 mL").check();
        form.getTextField('Prescribers Sig').setText(this.enrollment.pharmacyPrescriptionData.prescriberSignature || '');
        form.getTextField('Month').setText(this.getMonthOnly(moment().format('MM/DD/YYYY') ) || '');
        form.getTextField('Day').setText(this.getDateOnly(moment().format('MM/DD/YYYY') ) || '');
        form.getTextField('Year').setText(this.getYearOnly(moment().format('MM/DD/YYYY') ) || '');
        }
    }


    public pdfReadOnlyFieldsAdult( form: PDFForm){
        if(!this.patientIsJuvenile()){
            form.getTextField('First Name').enableReadOnly();
            form.getTextField('Last Name').enableReadOnly();
            form.getTextField('DB1').enableReadOnly();
            form.getTextField('DB2').enableReadOnly();
            form.getTextField('DB3').enableReadOnly();
            form.getTextField('Address').enableReadOnly();
            form.getTextField('City').enableReadOnly();
            form.getTextField('State').enableReadOnly();
            form.getTextField('Zip').enableReadOnly();
            form.getTextField('Home Phone').enableReadOnly();
            form.getTextField('Moblie Phone').enableReadOnly();
            form.getTextField('Email').enableReadOnly();
            form.getTextField('Language').enableReadOnly();
            form.getTextField('Beneficiary').enableReadOnly();
            form.getTextField('Prescription Insurance').enableReadOnly();
            form.getTextField('Medical Insurance').enableReadOnly();
            form.getTextField('RX Group').enableReadOnly();
            form.getTextField('RX ID').enableReadOnly();
            form.getTextField('Med Insurance ID').enableReadOnly();
            form.getTextField('Group Number').enableReadOnly();
            form.getTextField('RX BIN').enableReadOnly();
            form.getTextField('RX PCN').enableReadOnly();
            form.getTextField('Prescribers Name').enableReadOnly();
            form.getTextField('Office Contact Name').enableReadOnly();
            form.getTextField('Site Name').enableReadOnly();
            form.getTextField('Site Address').enableReadOnly();
            form.getTextField('Site City').enableReadOnly();
            form.getTextField('Site State').enableReadOnly();
            form.getTextField('Site Zip').enableReadOnly();
            form.getTextField('Office Phone').enableReadOnly();
            form.getTextField('Office Fax').enableReadOnly();
            form.getTextField('NPI').enableReadOnly();
            form.getTextField('Preferred Pharmacy').enableReadOnly();
            form.getTextField('Prescribers Sig').enableReadOnly();
            form.getTextField('Month').enableReadOnly();
            form.getTextField('Day').enableReadOnly();
            form.getTextField('Year').enableReadOnly();
            form.getTextField('Refills').enableReadOnly();
            form.getTextField('Refills_1').enableReadOnly();
            form.getTextField('Refills_2').enableReadOnly();
            form.getTextField('Refills_3').enableReadOnly();
            form.getTextField('Refills_4').enableReadOnly();
            form.getTextField('Refills_5').enableReadOnly();
            form.getTextField('Refills_6').enableReadOnly();
            form.getCheckBox("New").enableReadOnly();
            form.getCheckBox("Switch").enableReadOnly();
            form.getCheckBox("Restart").enableReadOnly();
            form.getCheckBox("All Services OR (select desired services)").enableReadOnly();
            form.getCheckBox("Benefits Investigation").enableReadOnly();
            form.getCheckBox("Prior Authorization/Appeal Support").enableReadOnly();
            form.getCheckBox("QuickStart Program").enableReadOnly();
            form.getCheckBox("Sharps Container").enableReadOnly();
            form.getCheckBox("Co-Pay Program").enableReadOnly();
            form.getCheckBox("Other Financial Assistance").enableReadOnly();
            form.getCheckBox("Welcome Packet").enableReadOnly();
            form.getCheckBox("Injection Training").enableReadOnly();
            form.getCheckBox("M").enableReadOnly();
            form.getCheckBox("F").enableReadOnly();
            form.getCheckBox("Interpreter Needed").enableReadOnly();
            form.getCheckBox("Hearing Impaired").enableReadOnly();
            form.getCheckBox("Documents Included").enableReadOnly();
            form.getCheckBox("Commercial/Private").enableReadOnly();
            form.getCheckBox("Medicare").enableReadOnly();
            form.getCheckBox("Medicaid, Government-Funded Plan, or VA").enableReadOnly();
            form.getCheckBox("Not Insured").enableReadOnly();
            form.getCheckBox("Rheumatoid Arthritis (RA)").enableReadOnly();
            form.getCheckBox("Psoriatic Arthritis (PsA)").enableReadOnly();
            form.getCheckBox("Ankylosing Spondylitis (AS)").enableReadOnly();
            form.getCheckBox("Crohn’s Disease (CD)").enableReadOnly();
            form.getCheckBox("Ulcerative Colitis (UC)").enableReadOnly();
            form.getCheckBox("Plaque Psoriasis (Ps)").enableReadOnly();
            form.getCheckBox("Hidradenitis Suppurativa (HS)").enableReadOnly();
            form.getCheckBox("Uveitis (UV)").enableReadOnly();
            form.getCheckBox("Do Not Send to Specialty Pharmacy").enableReadOnly();
            form.getCheckBox("HYRIMOZ Sensoready® Pen 40 mg/0.4 mLa").enableReadOnly();
            form.getCheckBox("Inject 40 mg SC every other week").enableReadOnly();
            form.getCheckBox("1 month").enableReadOnly();
            form.getCheckBox("3 months").enableReadOnly();
            form.getCheckBox("HYRIMOZ Prefilled Syringe 40 mg/04 mLa").enableReadOnly();
            form.getCheckBox("Inject 40 mg SC every weekb").enableReadOnly();
            form.getCheckBox("HYRIMOZ Sensoready Pen 80 mg/0.8 mL (RA only)").enableReadOnly();
            form.getCheckBox("Inject 80 mg SC every other weekb").enableReadOnly();
            form.getCheckBox("HYRIMOZ Prefilled Syringe 80 mg/08 mL (RA only)").enableReadOnly();
            form.getCheckBox("HYRIMOZ Sensoready Pen Starter Pack,").enableReadOnly();
            form.getCheckBox("Inject 160 mg SC on Day 1 (given in one day or split over two consecutive").enableReadOnly();
            form.getCheckBox("1 kit (Carton of 3)").enableReadOnly();
            form.getCheckBox("HYRIMOZ Sensoready Pen 40 mg/04 mLa").enableReadOnly();
            form.getCheckBox("1 month_1").enableReadOnly();
            form.getCheckBox("3 months_1").enableReadOnly();
            form.getCheckBox("HYRIMOZ Prefilled Syringe 40 mg/04 mLa_1").enableReadOnly();
            form.getCheckBox("HYRIMOZ Sensoready Pen 40 mg/04 mLa_1").enableReadOnly();
            form.getCheckBox("Inject 40 mg SC every other week starting on Day 29").enableReadOnly();
            form.getCheckBox("1 month_2").enableReadOnly();
            form.getCheckBox("3 months_2").enableReadOnly();
            form.getCheckBox("HYRIMOZ Prefilled Syringe 40 mg/04 mLa_2").enableReadOnly();
            form.getCheckBox("HYRIMOZ Sensoready® Pen Starter Pack,").enableReadOnly();
            form.getCheckBox("Inject 80 mg SC on Day 1, followed by 40 mg SC every other week").enableReadOnly();
            form.getCheckBox("1 kit (Carton of 3").enableReadOnly();
            form.getCheckBox("HYRIMOZ Sensoready Pen 40 mg/04 mLa_2").enableReadOnly();
            form.getCheckBox("1 month_3").enableReadOnly();
            form.getCheckBox("3 months_3").enableReadOnly();
            form.getCheckBox("HYRIMOZ Prefilled Syringe 40 mg/04 mLa_3").enableReadOnly();
            form.getCheckBox("HYRIMOZ Sensoready Pen 40 mg/04 mLa_3").enableReadOnly();
            form.getCheckBox("Inject 40 mg SC every other week_1").enableReadOnly();
            form.getCheckBox("1 month_4").enableReadOnly();
            form.getCheckBox("3 months_4").enableReadOnly();
            form.getCheckBox("HYRIMOZ Prefilled Syringe 40 mg/04 mLa_4").enableReadOnly();
            form.getCheckBox("HYRIMOZ Sensoready Pen Starter Pack,_1").enableReadOnly();
            form.getCheckBox("Inject 160 mg SC on Day 1 (given in one day or split over two consecutive_1").enableReadOnly();
            form.getCheckBox("1 kit (Carton of 3)_1").enableReadOnly();
            form.getCheckBox("HYRIMOZ Sensoready Pen 40 mg/04 mLa_4").enableReadOnly();
            form.getCheckBox("1 month_5").enableReadOnly();
            form.getCheckBox("3 months_5").enableReadOnly();
            form.getCheckBox("HYRIMOZ Prefilled Syringe 40 mg/04 mLa_5").enableReadOnly();
            form.getCheckBox("HYRIMOZ Sensoready Pen 40 mg/04 mLa_5").enableReadOnly();
            form.getCheckBox("Inject 40 mg SC every week starting on Day 29").enableReadOnly();
            form.getCheckBox("1 month_6").enableReadOnly();
            form.getCheckBox("3 months_6").enableReadOnly();
            form.getCheckBox("HYRIMOZ Prefilled Syringe 40 mg/0.4 mLa").enableReadOnly();
            form.getCheckBox("HYRIMOZ Sensoready Pen 80 mg/08 mL").enableReadOnly();
            form.getCheckBox("Inject 80 mg SC every other week starting on Day 29").enableReadOnly();
            form.getCheckBox("HYRIMOZ Prefilled Syringe 80 mg/08 mL").enableReadOnly();
        }
    }
    public pdfReadOnlyFields( form: PDFForm){
        if(!this.patientIsJuvenile()){
            form.getTextField('First Name').enableReadOnly();
            form.getTextField('Last Name').enableReadOnly();
            form.getTextField('DB1').enableReadOnly();
            form.getTextField('DB2').enableReadOnly();
            form.getTextField('DB3').enableReadOnly();
            form.getTextField('Address').enableReadOnly();
            form.getTextField('City').enableReadOnly();
            form.getTextField('State').enableReadOnly();
            form.getTextField('Zip').enableReadOnly();
            form.getTextField('Home Phone').enableReadOnly();
            form.getTextField('Moblie Phone').enableReadOnly();
            form.getTextField('Email').enableReadOnly();
            form.getTextField('Language').enableReadOnly();
            form.getTextField('Beneficiary').enableReadOnly();
            form.getTextField('Prescription Insurance').enableReadOnly();
            form.getTextField('Medical Insurance').enableReadOnly();
            form.getTextField('RX Group').enableReadOnly();
            form.getTextField('RX ID').enableReadOnly();
            form.getTextField('Med Insurance ID').enableReadOnly();
            form.getTextField('Group Number').enableReadOnly();
            form.getTextField('RX BIN').enableReadOnly();
            form.getTextField('RX PCN').enableReadOnly();
            form.getTextField('Prescribers Name').enableReadOnly();
            form.getTextField('Office Contact Name').enableReadOnly();
            form.getTextField('Site Name').enableReadOnly();
            form.getTextField('Site Address').enableReadOnly();
            form.getTextField('Site City').enableReadOnly();
            form.getTextField('Site State').enableReadOnly();
            form.getTextField('Site Zip').enableReadOnly();
            form.getTextField('Office Phone').enableReadOnly();
            form.getTextField('Office Fax').enableReadOnly();
            form.getTextField('NPI').enableReadOnly();
            form.getTextField('Preferred Pharmacy').enableReadOnly();
            form.getTextField('Prescribers Sig').enableReadOnly();
            form.getTextField('Month').enableReadOnly();
            form.getTextField('Day').enableReadOnly();
            form.getTextField('Year').enableReadOnly();

            form.getTextField('Refills').enableReadOnly();
            form.getTextField('Refills_1').enableReadOnly();
            form.getTextField('Refills_2').enableReadOnly();
            form.getTextField('Refills_3').enableReadOnly();
            form.getTextField('Refills_4').enableReadOnly();
            form.getTextField('Refills_5').enableReadOnly();
            form.getTextField('Refills_6').enableReadOnly();

            form.getCheckBox("New").enableReadOnly();
            form.getCheckBox("Switch").enableReadOnly();
            form.getCheckBox("Restart").enableReadOnly();

            form.getCheckBox("All Services OR (select desired services)").enableReadOnly();
            form.getCheckBox("Benefits Investigation").enableReadOnly();
            form.getCheckBox("Prior Authorization/Appeal Support").enableReadOnly();
            form.getCheckBox("QuickStart Program").enableReadOnly();
            form.getCheckBox("Sharps Container").enableReadOnly();
            form.getCheckBox("Co-Pay Program").enableReadOnly();
            form.getCheckBox("Other Financial Assistance").enableReadOnly();
            form.getCheckBox("Welcome Packet").enableReadOnly();
            form.getCheckBox("Injection Training").enableReadOnly();
            form.getCheckBox("M").enableReadOnly();
            form.getCheckBox("F").enableReadOnly();
            form.getCheckBox("Interpreter Needed").enableReadOnly();
            form.getCheckBox("Hearing Impaired").enableReadOnly();
            form.getCheckBox("Documents Included").enableReadOnly();
            form.getCheckBox("Commercial/Private").enableReadOnly();
            form.getCheckBox("Medicare").enableReadOnly();
            form.getCheckBox("Medicaid, Government-Funded Plan, or VA").enableReadOnly();
            form.getCheckBox("Not Insured").enableReadOnly();
            form.getCheckBox("Rheumatoid Arthritis (RA)").enableReadOnly();
            form.getCheckBox("Psoriatic Arthritis (PsA)").enableReadOnly();
            form.getCheckBox("Ankylosing Spondylitis (AS)").enableReadOnly();
            form.getCheckBox("Crohn’s Disease (CD)").enableReadOnly();
            form.getCheckBox("Ulcerative Colitis (UC)").enableReadOnly();
            form.getCheckBox("Plaque Psoriasis (Ps)").enableReadOnly();
            form.getCheckBox("Hidradenitis Suppurativa (HS)").enableReadOnly();
            form.getCheckBox("Uveitis (UV)").enableReadOnly();
            form.getCheckBox("Do Not Send to Specialty Pharmacy").enableReadOnly();

            form.getCheckBox("HYRIMOZ Sensoready® Pen 40 mg/0.4 mLa").enableReadOnly();
            form.getCheckBox("Inject 40 mg SC every other week").enableReadOnly();
            form.getCheckBox("1 month").enableReadOnly();
            form.getCheckBox("3 months").enableReadOnly();
            form.getCheckBox("HYRIMOZ Prefilled Syringe 40 mg/04 mLa").enableReadOnly();
            form.getCheckBox("Inject 40 mg SC every weekb").enableReadOnly();
            form.getCheckBox("HYRIMOZ Sensoready Pen 80 mg/0.8 mL (RA only)").enableReadOnly();
            form.getCheckBox("Inject 80 mg SC every other weekb").enableReadOnly();
            form.getCheckBox("HYRIMOZ Prefilled Syringe 80 mg/08 mL (RA only)").enableReadOnly();

            form.getCheckBox("HYRIMOZ Sensoready Pen Starter Pack,").enableReadOnly();
            form.getCheckBox("Inject 160 mg SC on Day 1 (given in one day or split over two consecutive").enableReadOnly();
            form.getCheckBox("1 kit (Carton of 3)").enableReadOnly();
            form.getCheckBox("HYRIMOZ Sensoready Pen 40 mg/04 mLa").enableReadOnly();
            form.getCheckBox("1 month_1").enableReadOnly();
            form.getCheckBox("3 months_1").enableReadOnly();
            form.getCheckBox("HYRIMOZ Prefilled Syringe 40 mg/04 mLa_1").enableReadOnly();
            form.getCheckBox("HYRIMOZ Sensoready Pen 40 mg/04 mLa_1").enableReadOnly();
            form.getCheckBox("Inject 40 mg SC every other week starting on Day 29").enableReadOnly();
            form.getCheckBox("1 month_2").enableReadOnly();
            form.getCheckBox("3 months_2").enableReadOnly();
            form.getCheckBox("HYRIMOZ Prefilled Syringe 40 mg/04 mLa_2").enableReadOnly();

            form.getCheckBox("HYRIMOZ Sensoready® Pen Starter Pack,").enableReadOnly();
            form.getCheckBox("Inject 80 mg SC on Day 1, followed by 40 mg SC every other week").enableReadOnly();
            form.getCheckBox("1 kit (Carton of 3").enableReadOnly();
            form.getCheckBox("HYRIMOZ Sensoready Pen 40 mg/04 mLa_2").enableReadOnly();
            form.getCheckBox("1 month_3").enableReadOnly();
            form.getCheckBox("3 months_3").enableReadOnly();
            form.getCheckBox("HYRIMOZ Prefilled Syringe 40 mg/04 mLa_3").enableReadOnly();
            form.getCheckBox("HYRIMOZ Sensoready Pen 40 mg/04 mLa_3").enableReadOnly();
            form.getCheckBox("Inject 40 mg SC every other week_1").enableReadOnly();
            form.getCheckBox("1 month_4").enableReadOnly();
            form.getCheckBox("3 months_4").enableReadOnly();
            form.getCheckBox("HYRIMOZ Prefilled Syringe 40 mg/04 mLa_4").enableReadOnly();

            form.getCheckBox("HYRIMOZ Sensoready Pen Starter Pack,_1").enableReadOnly();
            form.getCheckBox("Inject 160 mg SC on Day 1 (given in one day or split over two consecutive_1").enableReadOnly();
            form.getCheckBox("1 kit (Carton of 3)_1").enableReadOnly();
            form.getCheckBox("HYRIMOZ Sensoready Pen 40 mg/04 mLa_4").enableReadOnly();
            form.getCheckBox("1 month_5").enableReadOnly();
            form.getCheckBox("3 months_5").enableReadOnly();
            form.getCheckBox("HYRIMOZ Prefilled Syringe 40 mg/04 mLa_5").enableReadOnly();
            form.getCheckBox("HYRIMOZ Sensoready Pen 40 mg/04 mLa_5").enableReadOnly();
            form.getCheckBox("Inject 40 mg SC every week starting on Day 29").enableReadOnly();
            form.getCheckBox("1 month_6").enableReadOnly();
            form.getCheckBox("3 months_6").enableReadOnly();
            form.getCheckBox("HYRIMOZ Prefilled Syringe 40 mg/0.4 mLa").enableReadOnly();
            form.getCheckBox("HYRIMOZ Sensoready Pen 80 mg/08 mL").enableReadOnly();
            form.getCheckBox("Inject 80 mg SC every other week starting on Day 29").enableReadOnly();
            form.getCheckBox("HYRIMOZ Prefilled Syringe 80 mg/08 mL").enableReadOnly();
        }
        if(this.patientIsJuvenile()){
            form.getTextField('First name 2').enableReadOnly();
            form.getTextField('Last Name 2').enableReadOnly();
            form.getTextField('DB4').enableReadOnly();
            form.getTextField('DB5').enableReadOnly();
            form.getTextField('DB6').enableReadOnly();
            form.getTextField('Parent/Guardian').enableReadOnly();
            form.getTextField('Relationship').enableReadOnly();
            form.getTextField('Address 3').enableReadOnly();
            form.getTextField('City 3').enableReadOnly();
            form.getTextField('State 3').enableReadOnly();
            form.getTextField('Zip 3').enableReadOnly();
            form.getTextField('Home Phone 3').enableReadOnly();
            form.getTextField('Parent Mobile Phone').enableReadOnly();
            form.getTextField('Parent email').enableReadOnly();
            form.getTextField('Parent language').enableReadOnly();
            form.getTextField('Beneficiary 3').enableReadOnly();
            form.getTextField('Medical Ins 3').enableReadOnly();
            form.getTextField('Med Ins ID 3').enableReadOnly();
            form.getTextField('Group 3').enableReadOnly();
            form.getTextField('Scrip Ins 3').enableReadOnly();
            form.getTextField('RX Group 3').enableReadOnly();
            form.getTextField('RX BIN 3').enableReadOnly();
            form.getTextField('RX ID 3').enableReadOnly();
            form.getTextField('RX PCN 3').enableReadOnly();
            form.getTextField('Prescribers Name diag').enableReadOnly();
            form.getTextField('Office Contact diag').enableReadOnly();
            form.getTextField('Site name diag').enableReadOnly();
            form.getTextField('Address diag').enableReadOnly();
            form.getTextField('City diag').enableReadOnly();
            form.getTextField('State diag').enableReadOnly();
            form.getTextField('Zip diag').enableReadOnly();
            form.getTextField('Office Phone diag').enableReadOnly();
            form.getTextField('Office Fax diag').enableReadOnly();
            form.getTextField('NPI diag').enableReadOnly();
            form.getTextField('Pharmacy diag').enableReadOnly();
            form.getTextField('Refills diag').enableReadOnly();
            form.getTextField('Prescribers sig 3').enableReadOnly();
            form.getTextField('Date 4').enableReadOnly();
            form.getTextField('Date 5').enableReadOnly();
            form.getTextField('Date 6').enableReadOnly();
            form.getCheckBox("All Services OR select desired services_2").enableReadOnly();
            form.getCheckBox("Benefits Investigation_2").enableReadOnly();
            form.getCheckBox("Prior AuthorizationAppeal Support_2").enableReadOnly();
            form.getCheckBox("QuickStart Program_2").enableReadOnly();
            form.getCheckBox("Other Financial Assistance_2").enableReadOnly();
            form.getCheckBox("Welcome Packet_2").enableReadOnly();
            form.getCheckBox("Injection Training_2").enableReadOnly();
            form.getCheckBox("Copay Program_2").enableReadOnly();
            form.getCheckBox("New_2").enableReadOnly();
            form.getCheckBox("Switch_2").enableReadOnly();
            form.getCheckBox("Restart_2").enableReadOnly();
            form.getRadioGroup("Sex_2").enableReadOnly();
            form.getCheckBox("Interpreter Needed_2").enableReadOnly();
            form.getCheckBox("Hearing Impaired_2").enableReadOnly();
            form.getCheckBox("Documents Included_2").enableReadOnly();
            form.getCheckBox("CommercialPrivate_2").enableReadOnly();
            form.getCheckBox("Medicaid GovernmentFunded Plan or VA_2").enableReadOnly();
            form.getCheckBox("Not Insured_2").enableReadOnly();
            form.getCheckBox("Juvenile Idiopathic Arthritis JIA").enableReadOnly();
            form.getCheckBox("Pediatric Crohn").enableReadOnly();
            form.getCheckBox("Prior Authorization Form_2").enableReadOnly();
            form.getCheckBox("Benefits Verification Summary_2").enableReadOnly();
            form.getCheckBox("Do not send to Specialty Pharmacy_2").enableReadOnly();
            form.getCheckBox("10 kg 22 lbs to 15 kg 33 lbs").enableReadOnly();
            form.getCheckBox("Prefilled syringe 10 mg01 mL").enableReadOnly();
            form.getCheckBox("15 kg 33 lbs to 30 kg 66 lbs").enableReadOnly();
            form.getCheckBox("undefined_6").enableReadOnly();
            form.getCheckBox("Sensoready pen 40 mg04 mL_4").enableReadOnly();
            form.getCheckBox("Prefilled syringe 40 mg04 mL_4").enableReadOnly();
            form.getCheckBox("17 kg 37 lbs to 40 kg 88 lbs").enableReadOnly();
            form.getCheckBox("Prefilled syringe 20 mg02 mL").enableReadOnly();
            form.getCheckBox("40 kg 88 lbs and greater").enableReadOnly();
            form.getCheckBox("Sensoready pen 40 mg04 mL_5").enableReadOnly();
            form.getCheckBox("Prefilled syringe 40 mg04 mL_5").enableReadOnly();
            form.getTextField("Diagnosis").enableReadOnly();
        }
    }

       // Patient-Information-Juvinile Hyrimoz_Pediatric_Patient_Enrollment_Form
    public fieldMappingToPDFJuvenile(form: PDFForm){ 
        form.getTextField('First_Name_Jr').setText(this.enrollment.patientHcpInformationData.firstName || '');
        form.getTextField('First_Name_Jr').enableReadOnly();
        form.getTextField('Last_Name_Jr').setText(this.enrollment.patientHcpInformationData.lastName || '');
        form.getTextField('DB1_Jr').setText(this.enrollment.patientHcpInformationData.dateOfBirth ? this.getMonthOnly(this.enrollment.patientHcpInformationData.dateOfBirth) : '');
        form.getTextField('DB2_Jr').setText(this.enrollment.patientHcpInformationData.dateOfBirth ? this.getDateOnly(this.enrollment.patientHcpInformationData.dateOfBirth) : '');
        form.getTextField('DB3_Jr').setText(this.enrollment.patientHcpInformationData.dateOfBirth ? this.getYearOnly(this.enrollment.patientHcpInformationData.dateOfBirth) : '');
        form.getTextField('Parent/Guardian_Jr').setText(this.enrollment.patientHcpInformationData.guardianName || '');
        form.getTextField('Relationship_Jr').setText(this.enrollment.patientHcpInformationData.relationshipToPatient || '');
        form.getTextField('Address_Jr').setText(this.enrollment.patientHcpInformationData.addressOne || '');
        form.getTextField('City_Jr').setText(this.enrollment.patientHcpInformationData.city || '');
        form.getTextField('State_Jr').setText(this.capitalizeFirstLetter(states.find(i => i.code === this.enrollment.patientHcpInformationData.state)?.description || "") || '');
        form.getTextField('Zip_Jr').setText(this.enrollment.patientHcpInformationData.zip || '');
        form.getTextField('Home_Phone_Jr').setText(this.enrollment.patientHcpInformationData.homePhone || '');
        form.getTextField('Parent_Mobile_Phone_Jr').setText(this.enrollment.patientHcpInformationData.phone || '');
        form.getTextField('Parent_Email_Jr').setText(this.enrollment.patientHcpInformationData.email || '');
        form.getTextField('Language_Jr').setText(this.enrollment.patientHcpInformationData.Language || '');
        if(this.enrollment.patientHcpInformationData.allServices) form.getCheckBox("All_Services_Jr").check();
        if(this.enrollment.patientHcpInformationData.benefitsAuthorization) form.getCheckBox("Benefits_Investigation_Jr").check();
        if(this.enrollment.patientHcpInformationData.authorizationSupport) form.getCheckBox("Prior_AuthorizationAppeal_Support_Jr").check();
        if(this.enrollment.patientHcpInformationData.quickStart) form.getCheckBox("QuickStart_Program_Jr").check();
        // if(this.enrollment.patientHcpInformationData.sharpsContainer) form.getCheckBox("Sharps Container_Jr").check();
        if(this.enrollment.patientHcpInformationData.financialAssistance) form.getCheckBox("Other_Financial_Assistance_Jr").check();
        if(this.enrollment.patientHcpInformationData.starterKit) form.getCheckBox("Welcome_Packet_Jr").check();
        if(this.enrollment.patientHcpInformationData.injectionTraining) form.getCheckBox("Injection_Training_Jr").check();
        if(this.enrollment.patientHcpInformationData.CopayProgram) form.getCheckBox("Copay_Program_Jr").check();

        if(this.enrollment.patientHcpInformationData.patientIs==="New to Hyrimoz") form.getCheckBox("New_Jr").check();
        if(this.enrollment.patientHcpInformationData.patientIs==="Switching from other brand to Hyrimoz") form.getCheckBox("Switch_Jr").check();
        if(this.enrollment.patientHcpInformationData.patientIs==="Restarting Hyrimoz") form.getCheckBox("Restart_Jr").check();

        if(this.enrollment.patientHcpInformationData.gender === 'Male') form.getRadioGroup("Sex_Jr").select('M_2');
        if(this.enrollment.patientHcpInformationData.gender === 'Female') form.getRadioGroup("Sex_Jr").select('F_2');

        if(this.enrollment.patientHcpInformationData.hearing === 'Interpreter Needed') form.getCheckBox("Interpreter_Needed_Jr").check();
        if(this.enrollment.patientHcpInformationData.hearing === 'Hearing Impaired') form.getCheckBox("Hearing_Impaired_Jr").check();

        // health-insurence-Juvinile
        if(this.enrollment.healthInsuranceData.documentsIncluded) form.getCheckBox("Documents_Included_Jr").check();
        if(this.enrollment.healthInsuranceData.privateCommercial) form.getCheckBox("Commercial_Private_Jr").check();
        if(this.enrollment.healthInsuranceData.medicareCheckbox) form.getCheckBox("Medicare_Jr").check(); 
        if(this.enrollment.healthInsuranceData.govFundCheckbox) form.getCheckBox("Medicaid_GovernmentFunded_Plan_or_VA_Jr").check();
        if(this.enrollment.healthInsuranceData.notInsuredCheckbox) form.getCheckBox("Not_Insured_Jr").check();

        form.getTextField('Beneficiary_Jr').setText(this.enrollment.healthInsuranceData.medicalBeneficiaryName || '');
        form.getTextField('Medical_Insurance_Jr').setText(this.enrollment.healthInsuranceData.medicalInsuranceCarrier || '');
        form.getTextField('Med_Insurance_ID_Jr').setText(this.enrollment.healthInsuranceData.medicalPolicy || '');
        form.getTextField('Group_Number_Jr').setText(this.enrollment.healthInsuranceData.medicalGroup || '');

        form.getTextField('Prescription_Insurance_Jr').setText(this.enrollment.healthInsuranceData.prescriptionInsuranceCarrier || '');
        form.getTextField('RX_Group_Jr').setText(this.enrollment.healthInsuranceData.rxGroup || '');
        form.getTextField('RX_ID_Jr').setText(this.enrollment.healthInsuranceData.rxId || '');
        form.getTextField('RX_BIN_Jr').setText(this.enrollment.healthInsuranceData.rxBin || '');
        form.getTextField('RX_PCN_Jr').setText(this.enrollment.healthInsuranceData.rxPcn || '');

        // Diagnose-information-Juvinile
        if(this.enrollment.DiagnoseData.JuvenileIdiopathicArthritis) form.getCheckBox("Juvenile_Idiopathic_Arthritis_Jr").check();
        if(this.enrollment.DiagnoseData.PediatricCrohnDisease) form.getCheckBox("Pediatric_Crohn_Jr").check();

        // prescriber-information-Juvinile
        // if(this.enrollment.hcpPrescriberInformation.priorAuthorization) form.getCheckBox("Prior Authorization Form_2").check();
        // if(this.enrollment.hcpPrescriberInformation.benefitVerification) form.getCheckBox("Benefits Verification Summary_2").check();

        form.getTextField('Prescribers_Name_Jr').setText((this.enrollment.hcpPrescriberInformation.firstName + ' '+this.enrollment.hcpPrescriberInformation.lastName) || '');
        form.getTextField('Office_Contact_Name_Jr').setText(this.enrollment.hcpPrescriberInformation.officeContactName || '');
        form.getTextField('Site_Name_Jr').setText(this.enrollment.hcpPrescriberInformation.siteName || '');
        form.getTextField('Site_Address_Jr').setText(this.enrollment.hcpPrescriberInformation.address || '');
        form.getTextField('Site_City_Jr').setText(this.enrollment.hcpPrescriberInformation.city || '');
        form.getTextField('Site_State_Jr').setText(this.capitalizeFirstLetter(states.find(i => i.code === this.enrollment.hcpPrescriberInformation.state)?.description || "") || '');
        form.getTextField('Site_Zip_Jr').setText(this.enrollment.hcpPrescriberInformation.zip || '');
        form.getTextField('Office_Phone_Jr').setText(this.enrollment.hcpPrescriberInformation.phone || '');
        form.getTextField('Office_Fax_Jr').setText(this.enrollment.hcpPrescriberInformation.fax || '');
        form.getTextField('NPI_Jr').setText(this.enrollment.hcpPrescriberInformation.npi || '');

        // Pharmacy-prescription-Juvinile
        form.getTextField('Preferred_Pharmacy_Jr').setText(this.enrollment.pharmacyPrescriptionData.preferredSpecialty || '');
        if(this.enrollment.pharmacyPrescriptionData.specialtyPharmacy) form.getCheckBox("Do_not_send_to_Specialty_Pharmacy_Jr").check();
        // form.getTextField('Refills diag').setText(this.enrollment.pharmacyPrescriptionData.numberOfRefills || '');

        form.getTextField('Patient_Full_Name_Jr').setText(this.enrollment.patientHcpInformationData.firstName +" "+ this.enrollment.patientHcpInformationData.lastName || '');
        form.getTextField('DB4_Jr').setText(this.enrollment.patientHcpInformationData.dateOfBirth ? this.getMonthOnly(this.enrollment.patientHcpInformationData.dateOfBirth) : '');
        form.getTextField('DB5_Jr').setText(this.enrollment.patientHcpInformationData.dateOfBirth ? this.getDateOnly(this.enrollment.patientHcpInformationData.dateOfBirth) : '');
        form.getTextField('DB6_Jr').setText(this.enrollment.patientHcpInformationData.dateOfBirth ? this.getYearOnly(this.enrollment.patientHcpInformationData.dateOfBirth) : '');

        if(this.enrollment.PediatriccrohnsDiseaseData.PediatriccrohnsDisease11) form.getCheckBox("Initial_11_Jr").check();
        if(this.enrollment.PediatriccrohnsDiseaseData.PediatriccrohnsDisease12) form.getCheckBox("Initial_12_Jr").check();
        if(this.enrollment.PediatriccrohnsDiseaseData.PediatriccrohnsDisease13) form.getCheckBox("Initial_13_Jr").check();
        
        if(this.enrollment.PediatriccrohnsDiseaseData.PediatriccrohnsDisease21) form.getCheckBox("Initial_21_Jr").check();
        if(this.enrollment.PediatriccrohnsDiseaseData.PediatriccrohnsDisease22) form.getCheckBox("Initial_22_Jr").check();
        if(this.enrollment.PediatriccrohnsDiseaseData.PediatriccrohnsDisease23) form.getCheckBox("Initial_23_Jr").check();

        if(this.enrollment.PediatriccrohnsDiseaseData.PediatriccrohnsDisease31) form.getCheckBox("Initial_31_Jr").check();
        if(this.enrollment.PediatriccrohnsDiseaseData.PediatriccrohnsDisease32) form.getCheckBox("Initial_33_1_Jr").check();
        if(this.enrollment.PediatriccrohnsDiseaseData.PediatriccrohnsDisease33) form.getCheckBox("Initial_33_2_Jr").check();
        form.getTextField('Refills1_Jr').setText(this.enrollment.PediatriccrohnsDiseaseData.Refills33 || '');

        if(this.enrollment.PediatriccrohnsDiseaseData.PediatriccrohnsDisease41) form.getCheckBox("Initial_41_Jr").check();

        if(this.enrollment.PediatriccrohnsDiseaseData.PediatriccrohnsDisease51) form.getCheckBox("Maintenance_51_Jr").check();
        if(this.enrollment.PediatriccrohnsDiseaseData.PediatriccrohnsDisease52) form.getCheckBox("Maintenance_52_Jr").check();
        if(this.enrollment.PediatriccrohnsDiseaseData.PediatriccrohnsDisease53) form.getCheckBox("Maintenance_53_1_Jr").check();
        if(this.enrollment.PediatriccrohnsDiseaseData.PediatriccrohnsDisease54) form.getCheckBox("Maintenance_53_2_Jr").check();
        form.getTextField('Refills2_Jr').setText(this.enrollment.PediatriccrohnsDiseaseData.Refills44 || '');

        if(this.enrollment.PediatriccrohnsDiseaseData.PediatriccrohnsDisease61) form.getCheckBox("Maintenance_61_Jr").check();
        if(this.enrollment.PediatriccrohnsDiseaseData.PediatriccrohnsDisease62) form.getCheckBox("Maintenance_62_Jr").check();

        if(this.enrollment.PediatriccrohnsDiseaseData.PediatriccrohnsDisease71) form.getCheckBox("Maintenance_71_Jr").check();

        if(this.enrollment.JuvenileidiopathicArthritisData.JuvenileidiopathicArthritis11) form.getCheckBox("Juve_11").check();
        if(this.enrollment.JuvenileidiopathicArthritisData.JuvenileidiopathicArthritis12) form.getCheckBox("Juve_12").check();
        if(this.enrollment.JuvenileidiopathicArthritisData.JuvenileidiopathicArthritis41) form.getCheckBox("Juve_13_1").check();
        if(this.enrollment.JuvenileidiopathicArthritisData.JuvenileidiopathicArthritis42) form.getCheckBox("Juve_13_2").check();
        form.getTextField('Refills3_Jr').setText(this.enrollment.JuvenileidiopathicArthritisData.JuvenileRefills || '');

        if(this.enrollment.JuvenileidiopathicArthritisData.JuvenileidiopathicArthritis21) form.getCheckBox("Juve_21").check();
        if(this.enrollment.JuvenileidiopathicArthritisData.JuvenileidiopathicArthritis22) form.getCheckBox("Juve_22").check();
        if(this.enrollment.JuvenileidiopathicArthritisData.JuvenileidiopathicArthritis31) form.getCheckBox("Juve_31").check();
        if(this.enrollment.JuvenileidiopathicArthritisData.JuvenileidiopathicArthritis32) form.getCheckBox("Juve_32").check();
        if(this.enrollment.JuvenileidiopathicArthritisData.JuvenileidiopathicArthritis33) form.getCheckBox("Juve_41").check();
        
        form.getTextField('Prescribers_Sign_Jr').setText(this.enrollment.pharmacyPrescriptionData.prescriberSignature || '');
        form.getTextField('Date7_Jr').setText(this.getMonthOnly(moment().format('MM/DD/YYYY') ) || '');
        form.getTextField('Date8_Jr').setText(this.getDateOnly(moment().format('MM/DD/YYYY') ) || '');
        form.getTextField('Date9_Jr').setText(this.getYearOnly(moment().format('MM/DD/YYYY') ) || '');
    }

    public pdfReadOnlyFieldsJuvenile( form: PDFForm) {
        form.getTextField('First_Name_Jr').enableReadOnly();
        form.getTextField('Last_Name_Jr').enableReadOnly();
        form.getTextField('DB1_Jr').enableReadOnly();
        form.getTextField('DB2_Jr').enableReadOnly();
        form.getTextField('DB3_Jr').enableReadOnly();
        form.getTextField('Parent/Guardian_Jr').enableReadOnly();
        form.getTextField('Relationship_Jr').enableReadOnly();
        form.getTextField('Address_Jr').enableReadOnly();
        form.getTextField('City_Jr').enableReadOnly();
        form.getTextField('State_Jr').enableReadOnly();
        form.getTextField('Zip_Jr').enableReadOnly();
        form.getTextField('Home_Phone_Jr').enableReadOnly();
        form.getTextField('Parent_Mobile_Phone_Jr').enableReadOnly();
        form.getTextField('Parent_Email_Jr').enableReadOnly();
        form.getTextField('Language_Jr').enableReadOnly();

        form.getTextField('Patient_Full_Name_Jr').enableReadOnly();
        form.getTextField('DB4_Jr').enableReadOnly();
        form.getTextField('DB5_Jr').enableReadOnly();
        form.getTextField('DB6_Jr').enableReadOnly();

        form.getTextField('Beneficiary_Jr').enableReadOnly();
        form.getTextField('Medical_Insurance_Jr').enableReadOnly();
        form.getTextField('Med_Insurance_ID_Jr').enableReadOnly();
        form.getTextField('Group_Number_Jr').enableReadOnly();
        form.getTextField('Prescription_Insurance_Jr').enableReadOnly();
        form.getTextField('RX_Group_Jr').enableReadOnly();
        form.getTextField('RX_BIN_Jr').enableReadOnly();
        form.getTextField('RX_ID_Jr').enableReadOnly();
        form.getTextField('RX_PCN_Jr').enableReadOnly();
        form.getTextField('Prescribers_Name_Jr').enableReadOnly();
        form.getTextField('Office_Contact_Name_Jr').enableReadOnly();
        form.getTextField('Site_Name_Jr').enableReadOnly();
        form.getTextField('Site_Address_Jr').enableReadOnly();
        form.getTextField('Site_City_Jr').enableReadOnly();
        form.getTextField('Site_State_Jr').enableReadOnly();
        form.getTextField('Site_Zip_Jr').enableReadOnly();
        form.getTextField('Office_Phone_Jr').enableReadOnly();
        form.getTextField('Office_Fax_Jr').enableReadOnly();
        form.getTextField('NPI_Jr').enableReadOnly();
        form.getTextField('Preferred_Pharmacy_Jr').enableReadOnly();
        form.getTextField('Prescribers_Sign_Jr').enableReadOnly();
        form.getTextField('Date7_Jr').enableReadOnly();
        form.getTextField('Date8_Jr').enableReadOnly();
        form.getTextField('Date9_Jr').enableReadOnly();
        form.getCheckBox("All_Services_Jr").enableReadOnly();
        form.getCheckBox("Benefits_Investigation_Jr").enableReadOnly();
        form.getCheckBox("Prior_AuthorizationAppeal_Support_Jr").enableReadOnly();
        form.getCheckBox("QuickStart_Program_Jr").enableReadOnly();
        form.getCheckBox("Sharps_Container_Jr").enableReadOnly();
        form.getCheckBox("Other_Financial_Assistance_Jr").enableReadOnly();
        form.getCheckBox("Welcome_Packet_Jr").enableReadOnly();
        form.getCheckBox("Injection_Training_Jr").enableReadOnly();
        form.getCheckBox("Copay_Program_Jr").enableReadOnly();
        form.getCheckBox("New_Jr").enableReadOnly();
        form.getCheckBox("Switch_Jr").enableReadOnly();
        form.getCheckBox("Restart_Jr").enableReadOnly();
        form.getCheckBox("Restart_Jr").enableReadOnly();
        form.getTextField("Other_Brand_Name_Jr").enableReadOnly();
        form.getRadioGroup("Sex_Jr").enableReadOnly();
        form.getCheckBox("Interpreter_Needed_Jr").enableReadOnly();
        form.getCheckBox("Hearing_Impaired_Jr").enableReadOnly();
        form.getCheckBox("Documents_Included_Jr").enableReadOnly();
        form.getCheckBox("Commercial_Private_Jr").enableReadOnly();
        form.getCheckBox("Medicare_Jr").enableReadOnly();
        
        form.getCheckBox("Medicaid_GovernmentFunded_Plan_or_VA_Jr").enableReadOnly();
        form.getCheckBox("Not_Insured_Jr").enableReadOnly();
        form.getCheckBox("Juvenile_Idiopathic_Arthritis_Jr").enableReadOnly();
        form.getCheckBox("Pediatric_Crohn_Jr").enableReadOnly();

        // form.getCheckBox("Prior Authorization Form_2").enableReadOnly();
        // form.getCheckBox("Benefits Verification Summary_2").enableReadOnly();
        form.getCheckBox("Do_not_send_to_Specialty_Pharmacy_Jr").enableReadOnly();

        form.getCheckBox("Initial_11_Jr").enableReadOnly();
        form.getCheckBox("Initial_12_Jr").enableReadOnly();
        form.getCheckBox("Initial_13_Jr").enableReadOnly();

        form.getCheckBox("Initial_21_Jr").enableReadOnly();
        form.getCheckBox("Initial_22_Jr").enableReadOnly();
        form.getCheckBox("Initial_23_Jr").enableReadOnly();

        form.getCheckBox("Initial_31_Jr").enableReadOnly();
        form.getCheckBox("Initial_33_1_Jr").enableReadOnly();
        form.getCheckBox("Initial_33_2_Jr").enableReadOnly();

        form.getCheckBox("Initial_41_Jr").enableReadOnly();
        form.getCheckBox("Maintenance_51_Jr").enableReadOnly();
        form.getCheckBox("Maintenance_52_Jr").enableReadOnly();
        form.getCheckBox("Maintenance_53_1_Jr").enableReadOnly();
        form.getCheckBox("Maintenance_53_2_Jr").enableReadOnly();

        form.getCheckBox("Maintenance_61_Jr").enableReadOnly();
        form.getCheckBox("Maintenance_62_Jr").enableReadOnly();
        form.getCheckBox("Maintenance_71_Jr").enableReadOnly();

        form.getCheckBox("Juve_11").enableReadOnly();
        form.getCheckBox("Juve_12").enableReadOnly();
        form.getCheckBox("Juve_13_1").enableReadOnly();
        form.getCheckBox("Juve_13_2").enableReadOnly();
        form.getCheckBox("Juve_21").enableReadOnly();
        form.getCheckBox("Juve_22").enableReadOnly();
        form.getCheckBox("Juve_31").enableReadOnly();
        form.getCheckBox("Juve_32").enableReadOnly();
        form.getCheckBox("Juve_41").enableReadOnly();

        form.getTextField("Refills1_Jr").enableReadOnly();
        form.getTextField("Refills2_Jr").enableReadOnly();
        form.getTextField("Refills3_Jr").enableReadOnly();
    }
}
