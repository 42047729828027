import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _afe777cc = () => interopDefault(import('..\\pages\\error.vue' /* webpackChunkName: "pages_error" */))
const _594abf13 = () => interopDefault(import('..\\pages\\have-a-card.vue' /* webpackChunkName: "pages_have-a-card" */))
const _37f90a0f = () => interopDefault(import('..\\pages\\have-a-card\\index.vue' /* webpackChunkName: "pages_have-a-card_index" */))
const _508d0b17 = () => interopDefault(import('..\\pages\\have-a-card\\enrollment.vue' /* webpackChunkName: "pages_have-a-card_enrollment" */))
const _7db26900 = () => interopDefault(import('..\\pages\\have-a-card\\success.vue' /* webpackChunkName: "pages_have-a-card_success" */))
const _068dc89b = () => interopDefault(import('..\\pages\\rejection.vue' /* webpackChunkName: "pages_rejection" */))
const _79e662ba = () => interopDefault(import('..\\pages\\request-services.vue' /* webpackChunkName: "pages_request-services" */))
const _714688bc = () => interopDefault(import('..\\pages\\unlock.vue' /* webpackChunkName: "pages_unlock" */))
const _6f56be78 = () => interopDefault(import('..\\pages\\upload-documents\\index.vue' /* webpackChunkName: "pages_upload-documents_index" */))
const _62cf018a = () => interopDefault(import('..\\pages\\errors\\400.vue' /* webpackChunkName: "pages_errors_400" */))
const _62b2d288 = () => interopDefault(import('..\\pages\\errors\\401.vue' /* webpackChunkName: "pages_errors_401" */))
const _627a7484 = () => interopDefault(import('..\\pages\\errors\\403.vue' /* webpackChunkName: "pages_errors_403" */))
const _625e4582 = () => interopDefault(import('..\\pages\\errors\\404.vue' /* webpackChunkName: "pages_errors_404" */))
const _037eba7c = () => interopDefault(import('..\\pages\\errors\\500.vue' /* webpackChunkName: "pages_errors_500" */))
const _4016d09c = () => interopDefault(import('..\\pages\\patient\\authorization.vue' /* webpackChunkName: "pages_patient_authorization" */))
const _812d75f4 = () => interopDefault(import('..\\pages\\patient\\eligibility.vue' /* webpackChunkName: "pages_patient_eligibility" */))
const _4d5ea327 = () => interopDefault(import('..\\pages\\patient\\injection-training.vue' /* webpackChunkName: "pages_patient_injection-training" */))
const _807603da = () => interopDefault(import('..\\pages\\patient\\insurance.vue' /* webpackChunkName: "pages_patient_insurance" */))
const _ca76c61c = () => interopDefault(import('..\\pages\\patient\\insurance-information.vue' /* webpackChunkName: "pages_patient_insurance-information" */))
const _10e209d8 = () => interopDefault(import('..\\pages\\patient\\more-service.vue' /* webpackChunkName: "pages_patient_more-service" */))
const _1b0c02d8 = () => interopDefault(import('..\\pages\\patient\\nurse-ambassador.vue' /* webpackChunkName: "pages_patient_nurse-ambassador" */))
const _1effbf5d = () => interopDefault(import('..\\pages\\patient\\patient-information.vue' /* webpackChunkName: "pages_patient_patient-information" */))
const _6c4b469b = () => interopDefault(import('..\\pages\\patient\\prescriber-information.vue' /* webpackChunkName: "pages_patient_prescriber-information" */))
const _f20bc076 = () => interopDefault(import('..\\pages\\patient\\selection.vue' /* webpackChunkName: "pages_patient_selection" */))
const _631e8a35 = () => interopDefault(import('..\\pages\\patient\\services.vue' /* webpackChunkName: "pages_patient_services" */))
const _c73fce58 = () => interopDefault(import('..\\pages\\patient\\sharps-container.vue' /* webpackChunkName: "pages_patient_sharps-container" */))
const _643acefc = () => interopDefault(import('..\\pages\\patient\\success.vue' /* webpackChunkName: "pages_patient_success" */))
const _0251b064 = () => interopDefault(import('..\\pages\\patient\\welcome-kit.vue' /* webpackChunkName: "pages_patient_welcome-kit" */))
const _2d59334c = () => interopDefault(import('..\\pages\\pharmacy\\eligibility.vue' /* webpackChunkName: "pages_pharmacy_eligibility" */))
const _02a82890 = () => interopDefault(import('..\\pages\\pharmacy\\insurance-information.vue' /* webpackChunkName: "pages_pharmacy_insurance-information" */))
const _4837c726 = () => interopDefault(import('..\\pages\\pharmacy\\ncpdp.vue' /* webpackChunkName: "pages_pharmacy_ncpdp" */))
const _57d807a3 = () => interopDefault(import('..\\pages\\pharmacy\\patient-information.vue' /* webpackChunkName: "pages_pharmacy_patient-information" */))
const _6f38d042 = () => interopDefault(import('..\\pages\\pharmacy\\success.vue' /* webpackChunkName: "pages_pharmacy_success" */))
const _3d7b49d1 = () => interopDefault(import('..\\pages\\provider\\diagnosis-information.vue' /* webpackChunkName: "pages_provider_diagnosis-information" */))
const _23e15464 = () => interopDefault(import('..\\pages\\provider\\eligibility.vue' /* webpackChunkName: "pages_provider_eligibility" */))
const _9ad69cd2 = () => interopDefault(import('..\\pages\\provider\\health-insurance-information.vue' /* webpackChunkName: "pages_provider_health-insurance-information" */))
const _607b588c = () => interopDefault(import('..\\pages\\provider\\insurance-information.vue' /* webpackChunkName: "pages_provider_insurance-information" */))
const _640c8825 = () => interopDefault(import('..\\pages\\provider\\patient-information.vue' /* webpackChunkName: "pages_provider_patient-information" */))
const _7751f316 = () => interopDefault(import('..\\pages\\provider\\pharmacy-prescription.vue' /* webpackChunkName: "pages_provider_pharmacy-prescription" */))
const _51f72e5a = () => interopDefault(import('..\\pages\\provider\\prescriber-information.vue' /* webpackChunkName: "pages_provider_prescriber-information" */))
const _30047ae6 = () => interopDefault(import('..\\pages\\provider\\selection.vue' /* webpackChunkName: "pages_provider_selection" */))
const _944ef878 = () => interopDefault(import('..\\pages\\provider\\success.vue' /* webpackChunkName: "pages_provider_success" */))
const _8b513c32 = () => interopDefault(import('..\\pages\\provider\\summary.vue' /* webpackChunkName: "pages_provider_summary" */))
const _8d649fb0 = () => interopDefault(import('..\\pages\\provider\\upload-document.vue' /* webpackChunkName: "pages_provider_upload-document" */))
const _7413f5fb = () => interopDefault(import('..\\pages\\provider\\upload-documents.vue' /* webpackChunkName: "pages_provider_upload-documents" */))
const _3bcb9a9b = () => interopDefault(import('..\\pages\\transition\\document-submission.vue' /* webpackChunkName: "pages_transition_document-submission" */))
const _3f592c6a = () => interopDefault(import('..\\pages\\transition\\eligibility.vue' /* webpackChunkName: "pages_transition_eligibility" */))
const _2a57fa7e = () => interopDefault(import('..\\pages\\transition\\patient-information.vue' /* webpackChunkName: "pages_transition_patient-information" */))
const _4cee0860 = () => interopDefault(import('..\\pages\\transition\\success.vue' /* webpackChunkName: "pages_transition_success" */))
const _644625e4 = () => interopDefault(import('..\\pages\\index.vue' /* webpackChunkName: "pages_index" */))

// TODO: remove in Nuxt 3
const emptyFn = () => {}
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onComplete = emptyFn, onAbort) {
  return originalPush.call(this, location, onComplete, onAbort)
}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('#{BasePath}#/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/error",
    component: _afe777cc,
    name: "error"
  }, {
    path: "/have-a-card",
    component: _594abf13,
    children: [{
      path: "",
      component: _37f90a0f,
      name: "have-a-card"
    }, {
      path: "enrollment",
      component: _508d0b17,
      name: "have-a-card-enrollment"
    }, {
      path: "success",
      component: _7db26900,
      name: "have-a-card-success"
    }]
  }, {
    path: "/rejection",
    component: _068dc89b,
    name: "rejection"
  }, {
    path: "/request-services",
    component: _79e662ba,
    name: "request-services"
  }, {
    path: "/unlock",
    component: _714688bc,
    name: "unlock"
  }, {
    path: "/upload-documents",
    component: _6f56be78,
    name: "upload-documents"
  }, {
    path: "/errors/400",
    component: _62cf018a,
    name: "errors-400"
  }, {
    path: "/errors/401",
    component: _62b2d288,
    name: "errors-401"
  }, {
    path: "/errors/403",
    component: _627a7484,
    name: "errors-403"
  }, {
    path: "/errors/404",
    component: _625e4582,
    name: "errors-404"
  }, {
    path: "/errors/500",
    component: _037eba7c,
    name: "errors-500"
  }, {
    path: "/patient/authorization",
    component: _4016d09c,
    name: "patient-authorization"
  }, {
    path: "/patient/eligibility",
    component: _812d75f4,
    name: "patient-eligibility"
  }, {
    path: "/patient/injection-training",
    component: _4d5ea327,
    name: "patient-injection-training"
  }, {
    path: "/patient/insurance",
    component: _807603da,
    name: "patient-insurance"
  }, {
    path: "/patient/insurance-information",
    component: _ca76c61c,
    name: "patient-insurance-information"
  }, {
    path: "/patient/more-service",
    component: _10e209d8,
    name: "patient-more-service"
  }, {
    path: "/patient/nurse-ambassador",
    component: _1b0c02d8,
    name: "patient-nurse-ambassador"
  }, {
    path: "/patient/patient-information",
    component: _1effbf5d,
    name: "patient-patient-information"
  }, {
    path: "/patient/prescriber-information",
    component: _6c4b469b,
    name: "patient-prescriber-information"
  }, {
    path: "/patient/selection",
    component: _f20bc076,
    name: "patient-selection"
  }, {
    path: "/patient/services",
    component: _631e8a35,
    name: "patient-services"
  }, {
    path: "/patient/sharps-container",
    component: _c73fce58,
    name: "patient-sharps-container"
  }, {
    path: "/patient/success",
    component: _643acefc,
    name: "patient-success"
  }, {
    path: "/patient/welcome-kit",
    component: _0251b064,
    name: "patient-welcome-kit"
  }, {
    path: "/pharmacy/eligibility",
    component: _2d59334c,
    name: "pharmacy-eligibility"
  }, {
    path: "/pharmacy/insurance-information",
    component: _02a82890,
    name: "pharmacy-insurance-information"
  }, {
    path: "/pharmacy/ncpdp",
    component: _4837c726,
    name: "pharmacy-ncpdp"
  }, {
    path: "/pharmacy/patient-information",
    component: _57d807a3,
    name: "pharmacy-patient-information"
  }, {
    path: "/pharmacy/success",
    component: _6f38d042,
    name: "pharmacy-success"
  }, {
    path: "/provider/diagnosis-information",
    component: _3d7b49d1,
    name: "provider-diagnosis-information"
  }, {
    path: "/provider/eligibility",
    component: _23e15464,
    name: "provider-eligibility"
  }, {
    path: "/provider/health-insurance-information",
    component: _9ad69cd2,
    name: "provider-health-insurance-information"
  }, {
    path: "/provider/insurance-information",
    component: _607b588c,
    name: "provider-insurance-information"
  }, {
    path: "/provider/patient-information",
    component: _640c8825,
    name: "provider-patient-information"
  }, {
    path: "/provider/pharmacy-prescription",
    component: _7751f316,
    name: "provider-pharmacy-prescription"
  }, {
    path: "/provider/prescriber-information",
    component: _51f72e5a,
    name: "provider-prescriber-information"
  }, {
    path: "/provider/selection",
    component: _30047ae6,
    name: "provider-selection"
  }, {
    path: "/provider/success",
    component: _944ef878,
    name: "provider-success"
  }, {
    path: "/provider/summary",
    component: _8b513c32,
    name: "provider-summary"
  }, {
    path: "/provider/upload-document",
    component: _8d649fb0,
    name: "provider-upload-document"
  }, {
    path: "/provider/upload-documents",
    component: _7413f5fb,
    name: "provider-upload-documents"
  }, {
    path: "/transition/document-submission",
    component: _3bcb9a9b,
    name: "transition-document-submission"
  }, {
    path: "/transition/eligibility",
    component: _3f592c6a,
    name: "transition-eligibility"
  }, {
    path: "/transition/patient-information",
    component: _2a57fa7e,
    name: "transition-patient-information"
  }, {
    path: "/transition/success",
    component: _4cee0860,
    name: "transition-success"
  }, {
    path: "/",
    component: _644625e4,
    name: "index"
  }],

  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}
