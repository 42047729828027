// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".v-application--wrap{font-family:Jost\\*,sans-serif;font-size:16px}.v-application--wrap h1.display-1,.v-application--wrap h2.display-1,.v-application--wrap h3.display-1{color:var(--color-secondary);margin-bottom:16px}.v-stepper{box-shadow:none!important}.v-stepper .v-stepper__header{box-shadow:none!important;height:auto!important;width:65%;margin:0 auto}.v-stepper .v-stepper__header .v-stepper__label{text-align:center}.step-px-10{padding-right:40px;padding-left:40px}@media only screen and (max-device-width:500px){.step-px-10{margin-right:10px!important;margin-left:10px!important}}@media only screen and (max-device-width:600px){.v-stepper .v-stepper__header{box-shadow:none!important;height:auto!important;width:100%;margin:0 auto}.v-stepper .v-stepper__header .v-stepper__label{text-align:center}}", ""]);
// Exports
exports.locals = {
	"primaryColor": "#036",
	"secondaryColor": "#e94c26",
	"accentColor": "#c41230"
};
module.exports = exports;
